import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentOverview.styles';
import {mergeClasses} from '@fluentui/react-components';
import AgentDrawer from '../Drawer/AgentDrawer';
import {Spinner} from '@fluentui/react-components';
import AgentHeader from './AgentHeader';
import AgentDetails from './AgentDetails';
import AgentActivity from './activity/AgentActivity';
import {
    useGetAgentDetails,
    AgentTriggerRun,
    AgentScopeTypes,
    PatchAgentRequest,
    RunAgentTriggerRequest,
    usePatchAgent,
    useRunAgentTrigger,
} from '@/api/agents';
import AgentPauseDialog from './AgentPauseDialog';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';

interface AgentOverviewProps {
    agentId: string;
}

export default function AgentOverview(props: AgentOverviewProps) {
    const {agentId} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const [openInputDrawer, setOpenInputDrawer] = useState<boolean>(false);
    const [openPauseDialog, setOpenPauseDialog] = useState<boolean>(false);
    const [selectedRun, setSelectedRun] = useState<AgentTriggerRun | undefined>(undefined);

    const {agent, identity, definition, isLoading, isError} = useGetAgentDetails({
        agentId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    const hasInputs = useMemo(() => {
        // Check if the agent has any required settings
        const hasAgentSettings = definition?.settings?.some((setting) => {
            return setting.required;
        });
        if (hasAgentSettings) return true;

        // Check if the trigger has any required settings
        return definition?.triggers?.[0]?.settings?.some((setting) => {
            return setting.required;
        });
    }, [definition]);

    const {
        mutate: updateAgent,
        isLoading: isPatchAgentLoading,
        isError: isPatchAgentError,
    } = usePatchAgent({scope: AgentScopeTypes.WORKSPACE});

    const {
        mutate: runAgentTrigger,
        isLoading: isRunAgentTriggerLoading,
        isError: isRunAgentTriggerError,
    } = useRunAgentTrigger({scope: AgentScopeTypes.WORKSPACE});

    const handleRunTrigger = useCallback(
        (body: RunAgentTriggerRequest['body']) => {
            const triggerName = agent?.triggers?.[0]?.name;
            if (!triggerName) return;
            const runAgentTriggerRequest: RunAgentTriggerRequest = {
                agentId,
                triggerName,
                body,
            };
            runAgentTrigger(runAgentTriggerRequest, {
                onSuccess: () => {
                    setOpenInputDrawer(false);
                },
            });
        },
        [agent, agentId, runAgentTrigger],
    );

    const handleEnableTrigger = useCallback(
        (enabled: boolean) => {
            const triggerName = agent?.triggers?.[0]?.name;
            if (!triggerName) return;
            const patchAgentRequest: PatchAgentRequest = {
                agentId: agentId,
                body: {
                    triggers: [
                        {
                            name: triggerName,
                            pollPeriodSeconds: enabled
                                ? definition?.triggers?.[0]?.defaultPollPeriodSeconds
                                : 0, // Triggers are disabled from automatically running by setting pollPeriodSeconds to 0
                        },
                    ],
                },
            };
            updateAgent(patchAgentRequest);
        },
        [agent, definition, agentId, updateAgent],
    );

    const handleClosePauseDialog = useCallback(() => {
        setOpenPauseDialog(false);
    }, []);

    const handleCloseDrawer = useCallback(() => {
        setSelectedRun(undefined);
        setOpenInputDrawer(false);
    }, []);

    const handlePauseAgentTrigger = useCallback(() => {
        handleEnableTrigger(false);
        handleClosePauseDialog();
    }, [handleEnableTrigger, handleClosePauseDialog]);

    const handleManualRun = useCallback(() => {
        if (hasInputs) setOpenInputDrawer(true);
        else handleRunTrigger({});
    }, [hasInputs, handleRunTrigger]);

    useEffect(() => {
        if (selectedRun) {
            // The input drawer content should not be shown when a run is selected
            setOpenInputDrawer(false);
        }
    }, [selectedRun]);

    return (
        <div className={classes.root}>
            {(isLoading || isError) && (
                <div className={classes.loading}>
                    {!isError && (
                        <Spinner
                            labelPosition="below"
                            label={tAgents('Agent.Loading')}
                            data-testid="agent-loading-spinner"
                        />
                    )}
                    {isError && <>{tAgents('Agent.Error')}</>}
                </div>
            )}
            {!isLoading && !isError && agent && identity && definition && (
                <>
                    <div className={classes.content}>
                        <AgentHeader
                            agent={agent}
                            definition={definition}
                            onManualRun={handleManualRun}
                            onEnableTrigger={handleEnableTrigger}
                            setOpenPauseDialog={setOpenPauseDialog}
                        />
                        <div
                            className={mergeClasses(
                                classes.container,
                                (!!selectedRun || openInputDrawer) && classes.wrapContainer,
                            )}
                        >
                            <AgentDetails
                                agent={agent}
                                identity={identity}
                                definition={definition}
                            />
                            <AgentActivity
                                setSelectedRun={setSelectedRun}
                                onManualRun={handleManualRun}
                                onEnableTrigger={handleEnableTrigger}
                                agentId={agentId}
                                agent={agent}
                                definition={definition}
                            />
                        </div>
                    </div>
                    <AgentDrawer
                        agent={agent}
                        agentDefinition={definition}
                        onClose={handleCloseDrawer}
                        selectedRun={selectedRun}
                        isManualRunLoading={isRunAgentTriggerLoading}
                        openInputDrawer={openInputDrawer}
                        onRunTrigger={handleRunTrigger}
                        hasInputs={!!hasInputs}
                    />
                </>
            )}
            <AgentPauseDialog
                onClose={handleClosePauseDialog}
                onPause={handlePauseAgentTrigger}
                open={openPauseDialog}
                agent={agent}
            />
            {isPatchAgentLoading && (
                <ToastNotification
                    intent={Intent.Progress}
                    timeout={-1000} // Set to negative to prevent timeout dismissal
                    data-testid="agent-toast-patch-loading"
                    message={tAgents('Agent.AgentPatchLoading')}
                    position="bottom-start"
                    vertical={33}
                    horizontal={33}
                ></ToastNotification>
            )}
            {isRunAgentTriggerLoading && (
                <ToastNotification
                    intent={Intent.Progress}
                    timeout={-1000} // Set to negative to prevent timeout dismissal
                    data-testid="agent-toast-run-loading"
                    message={tAgents('Agent.AgentTriggerRunLoading')}
                    position="bottom-start"
                    vertical={33}
                    horizontal={33}
                ></ToastNotification>
            )}
            {isPatchAgentError && (
                <ToastNotification
                    intent={Intent.Error}
                    data-testid="agent-toast-patch-error"
                    message={tAgents('Agent.AgentPatchError')}
                    position="bottom-start"
                    vertical={33}
                    horizontal={33}
                ></ToastNotification>
            )}
            {isRunAgentTriggerError && (
                <ToastNotification
                    intent={Intent.Error}
                    data-testid="agent-toast-run-error"
                    message={tAgents('Agent.AgentTriggerRunError')}
                    position="bottom-start"
                    vertical={33}
                    horizontal={33}
                ></ToastNotification>
            )}
        </div>
    );
}
