import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    drawerInputHeader: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
        maxHeight: '220px',
        overflow: 'auto',
    },
    accordionHeader: {
        '> button': {
            ...shorthands.padding(0),
        },
    },
    accordionPanel: {
        paddingTop: tokens.spacingVerticalL,
        ...shorthands.margin(0),
    },
    inputItem: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding(tokens.spacingVerticalS, 0),
    },
});
