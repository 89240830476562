import {useEffect, useState, useRef} from 'react';
import useClasses from './Build.styles';
import {BuildProps} from './Build.types';
import {useGetSkillsetCode} from '@/api/skills';
import ComponentsSideMenu from './ComponentsSideMenu';
import PluginCodeEditor from './PluginCodeEditor';
import {Button, Label} from '@fluentui/react-components';
import {SaveIcon} from '@/components/ui/icons';
import * as yaml from 'js-yaml';
import {YamlManifest} from './ComponentsSideMenu.types';
import ConfigureForm from './ConfigureForm';

export default function Build({pluginName}: BuildProps) {
    const classes = useClasses();
    const [rawYamlContent, setRawYamlContent] = useState<string>('');
    const [yamlContent, setYamlContent] = useState<YamlManifest | undefined>();
    const [isCodeEditor, setIsCodeEditor] = useState<boolean>(false);
    const [selectedComponent, setSelectedComponent] = useState<string>('');
    const pluginCodeEditorRef = useRef<{handleUpdateSkillset: () => void}>(null);
    const {
        data: skillset,
        refetch: refetchSkillset,
        isSuccess: isSuccess,
    } = useGetSkillsetCode({
        skillset: pluginName,
    });

    const handleSaveButtonClick = () => {
        if (pluginCodeEditorRef.current) {
            pluginCodeEditorRef.current.handleUpdateSkillset();
        }
    };

    const handleValueChange = (updatedYaml: string | undefined) => {
        if (updatedYaml) {
            try {
                const parsedObj = yaml.load(updatedYaml);
                setYamlContent(parsedObj as YamlManifest);
                setRawYamlContent(updatedYaml);
            } catch (e) {
                console.error('Error parsing yaml:', e);
            }
        }
    };

    const handleComponentsSelection = (name: string | undefined) => {
        setSelectedComponent(name ?? '');
    };

    useEffect(() => {
        console.log('Selected skillset:', skillset, ' ', isSuccess);
    }, [skillset, isSuccess]);

    return (
        <div className={classes.grid}>
            <ComponentsSideMenu
                parsedYaml={yamlContent}
                selectedComponent={selectedComponent}
                onComponentsSelection={(name) => handleComponentsSelection(name)}
            />
            <div className={classes.editorContainer}>
                <div className={classes.header}>
                    <Label className={classes.label}>{skillset ?? 'agent/plugin'}</Label>
                    <Button
                        className={classes.toggleSwitch1}
                        shape="circular"
                        onClick={() => setIsCodeEditor(false)}
                    >
                        Configure
                    </Button>
                    <Button
                        className={classes.toggleSwitch2}
                        shape="circular"
                        onClick={() => setIsCodeEditor(true)}
                    >
                        Code editor
                    </Button>
                    <Button
                        aria-label="Save"
                        className={classes.saveButton}
                        icon={<SaveIcon filled />}
                        onClick={() => handleSaveButtonClick()}
                    />
                </div>
                {isCodeEditor ? (
                    <PluginCodeEditor
                        currentSkillsetYaml={rawYamlContent}
                        ref={pluginCodeEditorRef}
                        onValueChange={(updatedYaml) => handleValueChange(updatedYaml)}
                    />
                ) : (
                    <ConfigureForm name={selectedComponent} />
                )}
            </div>
        </div>
    );
}
