import {useTranslation} from 'react-i18next';
import {Card, CardPreview, CardFooter} from '@fluentui/react-components';
import {useNavigate} from 'react-router-dom';

import useClasses from './CreateLanding.styles';

export default function CreateLanding() {
    const classes = useClasses();
    const {t} = useTranslation('pluginBuilder');
    const navigate = useNavigate();

    const imagePath = '/images/icons/';

    const handleCardKeyDown = (
        ev: React.KeyboardEvent<HTMLDivElement>,
        handleFunction: Function,
    ) => {
        if (ev.key === 'Enter' || ev.key === ' ') {
            handleFunction();
        }
    };

    const handleCreateNewAgent = () => {
        navigate(`/builder`);
    };

    const handleCreateNewPlugin = () => {
        navigate(`/`);
    };

    const handleUploadYAML = () => {
        navigate(`/`);
    };

    const cardList = [
        {
            title: 'CreateNewAgentTitle',
            description: 'CreateNewAgentDescription',
            icon: 'CreateNewAgentIcon',
            handleClick: handleCreateNewAgent,
        },
        {
            title: 'CreateNewPluginTitle',
            description: 'CreateNewPluginDescription',
            icon: 'CreateNewPluginIcon',
            handleClick: handleCreateNewPlugin,
        },
        {
            title: 'UploadYAMLTitle',
            description: 'UploadYAMLDescription',
            icon: 'UploadYAMLIcon',
            handleClick: handleUploadYAML,
        },
    ];

    return (
        <div className={classes.root}>
            <h1 className={classes.title}>{t('CreateTitle')}</h1>
            <div className={classes.subtitle}>{t('CreateSubtitle')}</div>
            <div className={classes.container}>
                <div className={classes.sectionTitle}>{t('CreateStartFromScratchTitle')}</div>
                <div className={classes.sectionDescription}>
                    {t('CreateStartFromScratchDescription')}
                </div>
                <div className={classes.cardHolder}>
                    {cardList.map((card, index) => (
                        <div key={index} className={classes.card}>
                            <Card
                                className={classes.card}
                                onClick={card.handleClick}
                                onKeyDown={(ev) => handleCardKeyDown(ev, card.handleClick)}
                            >
                                <CardPreview className={classes.cardPreview}>
                                    <img
                                        src={`${imagePath}${card.icon}.svg`}
                                        alt={t(card.title)}
                                        className={classes.cardIcon}
                                    />
                                </CardPreview>
                                <CardFooter className={classes.cardFooter}>
                                    <div className={classes.sectionTitle}>{t(card.title)}</div>
                                    <div className={classes.sectionDescription}>
                                        {t(card.description)}
                                    </div>
                                </CardFooter>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
