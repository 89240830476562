import {useQuery, UseQueryOptions} from '@tanstack/react-query';

import {DEFAULT_API_RETRY_COUNT, WORKSPACE_PROVIDER_DEFAULT_STALE_TIME} from '../api.constants';
import {useCustomPutWorkspace, Workspace} from '@/api/workspaces';
import useIsCapacityExpired from '@/api/capacities/useIsCapacityExpired';
import {Capacity} from '@/api/capacities';

interface AvailableCapacitiesResponse {
    attachedOwnedCapacities: Capacity[];
    attachedUnownedCapacities: Capacity[];
    unattachedCapacities: Capacity[];
}

function useGetAvailableCapacities(
    ownedWorkspaces?: string[],
    workspaces?: Workspace[],
    capacities?: Capacity[],
    useQueryOptions?: Pick<
        UseQueryOptions<AvailableCapacitiesResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customPutWorkspace} = useCustomPutWorkspace();
    const isCapacityExpired = useIsCapacityExpired();
    const ownedWorkspaceName = ownedWorkspaces?.[0];
    const ownedWorkspace = workspaces?.find((workspace) => workspace.name === ownedWorkspaceName);

    const nonExpiredCapacities =
        capacities?.filter((capacity) => !isCapacityExpired(capacity)) || [];

    return useQuery<AvailableCapacitiesResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces', 'workspacesOwnerCheck', 'capacities', ownedWorkspaceName],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async (): Promise<AvailableCapacitiesResponse> => {
            let capacitiesResponse: Capacity[] = [];

            //If we are missing any data we can't proceed, return empty object.
            if (!ownedWorkspace || !capacities || !ownedWorkspace) {
                return {} as AvailableCapacitiesResponse;
            }

            await Promise.all(
                (capacities || []).map(async (capacity) => {
                    //Check if user is owner of workspace by simulating a workspace update
                    let response = await customPutWorkspace<Response>({
                        request: {
                            name: ownedWorkspace.name,
                            capacity: {
                                type: 'CapacityReference',
                                referenceName: capacity.name,
                            },
                        },
                        targetWorkspace: ownedWorkspace,
                        whatIfHeader: true,
                    });

                    if (!response?.ok) {
                        capacitiesResponse.push(capacity);
                    }
                }),
            );

            const attachedOwnedCapacityNames = workspaces
                ?.filter((workspace) => ownedWorkspaces?.includes(workspace.name))
                ?.map((workspace) => workspace.capacity?.referenceName);
            const attachedOwnedCapacities = nonExpiredCapacities.filter((capacity) =>
                attachedOwnedCapacityNames?.includes(capacity.name),
            );
            const attachedUnownedCapacities = capacitiesResponse.filter(
                (capacity) => !attachedOwnedCapacityNames?.includes(capacity.name),
            );
            //list of capacities that are not in attachedOwnedCapacities or attachedUnownedCapacities

            const unattachedCapacities = nonExpiredCapacities
                .filter((capacity) => !attachedOwnedCapacities?.includes(capacity))
                .filter((capacity) => !attachedUnownedCapacities?.includes(capacity));

            return {
                attachedOwnedCapacities,
                attachedUnownedCapacities,
                unattachedCapacities,
            } as AvailableCapacitiesResponse;
        },
        ...useQueryOptions,
    });
}

export default useGetAvailableCapacities;
