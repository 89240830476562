import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {UserProfile} from './user.types';

export default function useGetUserProfileByUserId(userId?: string) {
    const {customFetch} = useFetch();

    return useQuery(['userProfile', userId], async () => {
        if (!userId) {
            return null; // Handle the case when userId is not provided
        }
        const data = await customFetch<UserProfile>(`/users/${userId}/getProfile`, {
            method: 'GET',
        });

        return data;
    });
}
