import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingHorizontalS,
        flex: 1,
        alignItems: 'center',
    },
    skeleton: {
        width: '50%',
    },
    skeletonItem: {
        height: tokens.lineHeightBase300,
    },
});
