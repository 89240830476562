import {useQuery} from '@tanstack/react-query';
import {AgentTriggerRun, AgentTriggerRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentTriggerRuns({
    agentId,
    triggerName,
    requestParams,
}: AgentTriggerRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['agents', agentId, 'triggers', triggerName, 'runs', requestParams],
        refetchOnWindowFocus: false,
        enabled: !!agentId && !!triggerName,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<{values: Array<AgentTriggerRun>}>(
                `/agents/${agentId}/triggers/${triggerName}/runs?${queryParams.toString()}`,
                {method: 'GET'},
            );
        },
        retry: false,
        refetchInterval: (data) => {
            if (!data || data.values.length == 0) {
                return false;
            }

            // Check if there are any runs that are InProgress, if so refetch every 2 seconds
            const runInProgress = data?.values?.some(
                (run: AgentTriggerRun) => run.status === 'InProgress' || run.status === 'Pending',
            );
            if (!runInProgress) return false;

            // If there are runs that are in progress, refetch every 3 seconds
            return 2000;
        },
    });
}
