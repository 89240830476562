import {
    Body1,
    Body1Strong,
    Field,
    Radio,
    RadioGroup,
    RadioGroupOnChangeData,
    SpinButton,
    SpinButtonChangeEvent,
    SpinButtonOnChangeData,
    Switch,
} from '@fluentui/react-components';
import useClasses from './ChangeCapacity.styles';
import {useTranslation} from 'react-i18next';
import {STATIC_CONTENT} from './ChangeCapacity.constants';
import {FormEvent, useCallback, useState} from 'react';

/**
 * Represents the event arguments for the OveragePanel component.
 */
interface OveragePanelEventArgs {
    /**
     * Indicates whether overage is used.
     */
    useOverage: boolean;

    /**
     * Indicates whether unlimited overage is allowed.
     */
    unlimitedOverage: boolean;

    /**
     * The limit value for overage.
     */
    limitValue: number;
}

/**
 * Props for the OveragePanel component.
 */
interface OveragePanelProps {
    /**
     * Indicates if overage units are needed.
     */
    needsOverageUnits: boolean;

    /**
     * Indicates if overage is unlimited.
     */
    unlimitedOverage: boolean;

    /**
     * The limit value for overage, if applicable.
     */
    limitValue?: number;

    /**
     * The cost per SCU (Service Capacity Unit).
     */
    scuCost: number;

    /**
     * Callback function to handle changes in overage usage.
     * @param useOverage - The event arguments for overage change.
     */

    onOverageChange: (useOverage: OveragePanelEventArgs) => void;
}

export default function OveragePanel(props: OveragePanelProps) {
    const classes = useClasses();
    const {t: tAdmin} = useTranslation('admin');
    const [needOverage, setNeedOverage] = useState(props.needsOverageUnits);
    const [limitValue, setLimitValue] = useState(props.limitValue ?? 1);
    const [unlimited, setUnlimited] = useState<boolean>(
        props.unlimitedOverage === null || props.unlimitedOverage === undefined
            ? true
            : props.unlimitedOverage,
    );
    const handleRadioChange = useCallback(
        (ev: FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData): void => {
            if (data.value === 'nolimit') {
                setUnlimited(true);
                setLimitValue(0);
                props.onOverageChange({
                    useOverage: needOverage,
                    unlimitedOverage: true,
                    limitValue: 0,
                });
            } else {
                setUnlimited(false);
                setLimitValue(1);
                props.onOverageChange({
                    useOverage: needOverage,
                    unlimitedOverage: false,
                    limitValue: 1,
                });
            }
        },
        [needOverage, props, unlimited],
    );

    function handleOverageLimitChange(
        event: SpinButtonChangeEvent,
        data: SpinButtonOnChangeData,
    ): void {
        let validatedValue;
        if (data.value !== undefined) {
            setLimitValue(data.value as number);
            validatedValue = data.value as number;
        } else if (data.displayValue !== undefined) {
            validatedValue = parseFloat(data.displayValue);
            if (!Number.isNaN(validatedValue)) {
                setLimitValue(validatedValue);
            } else {
                console.error('Invalid value for overage limit');
            }
        }
        props.onOverageChange({
            useOverage: needOverage,
            unlimitedOverage: false,
            limitValue: validatedValue as number,
        });
    }

    return (
        <>
            <div className={classes.overagePanelbackground}>
                <div className={classes.overageSwitchSection}>
                    <Switch
                        checked={needOverage}
                        data-testid="overage-switch"
                        onChange={() => {
                            setNeedOverage(!needOverage);
                            setUnlimited(true);
                            setLimitValue(0);
                            props.onOverageChange({
                                useOverage: !needOverage,
                                unlimitedOverage: true,
                                limitValue: 0,
                            });
                        }}
                    ></Switch>
                    <div className={classes.overageSwitchContent}>
                        <Body1Strong>{tAdmin(STATIC_CONTENT.USE_OVERAGE_UNITS)}</Body1Strong>
                        <Body1>
                            {tAdmin(STATIC_CONTENT.USD_PER_UNIT, {
                                cost: `$ ${props.scuCost}`,
                            })}
                        </Body1>
                    </div>
                </div>

                <div className={classes.overageContentSection}>
                    <Body1Strong>{tAdmin(STATIC_CONTENT.HOW_OVERAGE_WORKS)}</Body1Strong>
                    <Body1>{tAdmin(STATIC_CONTENT.HOW_OVERAGE_WORKS_DESCRIPTION)}</Body1>
                </div>
                {needOverage && (
                    <>
                        <div data-testid="overage-units-section">
                            <Field label={tAdmin(STATIC_CONTENT.NO_OF_ON_DEMAND_UNITS)}>
                                <RadioGroup onChange={handleRadioChange}>
                                    <Radio
                                        //data-test-id="no-limit-radio"
                                        key="nolimit"
                                        value="nolimit"
                                        checked={unlimited === true}
                                        label={tAdmin(STATIC_CONTENT.NO_LIMIT)}
                                    />
                                    <Radio
                                        //data-test-id="max-limit-radio"
                                        key="maxlimit"
                                        value="maxlimit"
                                        checked={unlimited === false}
                                        label={tAdmin(STATIC_CONTENT.SET_MAX_LIMIT)}
                                    />
                                </RadioGroup>
                            </Field>
                            {!unlimited && (
                                <>
                                    <SpinButton
                                        data-testid="overage-limit-input"
                                        className={classes.unitsInput}
                                        min={1}
                                        max={999}
                                        value={limitValue}
                                        onChange={handleOverageLimitChange}
                                    ></SpinButton>{' '}
                                    {tAdmin(STATIC_CONTENT.UNITS_PER_HOUR)}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
