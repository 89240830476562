import {useEffect, useState} from 'react';
import {Button, Link, mergeClasses, Title1} from '@fluentui/react-components';
import useClasses from './DataOptIn.styles';
import {
    useGetWorkspaceByName,
    useGetWorkspaceSettings,
    useUpdateWorkspaceSettings,
} from '@/api/workspaces';
import {useUpdateWorkspace} from '@/api/workspaces';
import {ErrorIcon, OpenIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import {useGetTenantInfo} from '@/api/tenant';
import {CustomSwitchTransition} from '../ui/Switch/switch.types';
import ProgressiveSwitch from '../ui/Switch';
import MSManagedBanner from './MSManagedBanner';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {WorkspaceSettings} from '@/api/workspaces/workspaces.types';
import {getCurrentWorkspaceCache} from '@/components/sections/workspaces/WorkspacesProvider';

interface DataAndPrivacyDetails {
    isAllowProductImprovement: boolean;
    isAllowModelImprovement: boolean;
}
// These are MSFT managed tenants that we don't want settings changed in
const managedTenants = MedeinaVariables.MSManagedTenants;

export default function OrganizationData() {
    const classes = useClasses();
    // Get the workspace name from the cache
    const workspaceCache = getCurrentWorkspaceCache();
    const workspaceName = workspaceCache?.name;
    const {
        data: dataOptStatus,
        isFetched: isDataFetched,
        isSuccess: isDataSuccess,
        isError: isDataError,
    } = useGetWorkspaceByName(workspaceName ?? '', {
        enabled: !!workspaceName,
    });
    const {data: tenantInfo, isSuccess: isTenantInfoSuccess} = useGetTenantInfo();
    const overrideSettings = useFeatureFlag(MedeinaFeatures.OverrideSettings);
    const disableSettingsUpdate = tenantInfo && managedTenants.includes(tenantInfo?.tenantId ?? '');

    const [isAllowProductImprovement, setIsAllowProductImprovement] = useState<boolean>(false);
    const [isAllowModelImprovement, setIsAllowModelImprovement] = useState<boolean>(false);
    const [errorTagForProductImprovementConsent, setErrorTagForProductImprovementConsent] =
        useState<boolean>(false);
    const [errorTagForModelImprovementConsent, setErrorTagForModelImprovementConsent] =
        useState<boolean>(false);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const [updateProductImprovementConsent, setProductImprovementConsent] =
        useState<CustomSwitchTransition>({
            isChanging: false,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
    const [updateModelImprovementConsent, setModelImprovementConsent] =
        useState<CustomSwitchTransition>({
            isChanging: false,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
    const {mutate: updateWorkspace} = useUpdateWorkspace();
    // multi-workspaces
    const {
        data: workspace,
        isFetched: workspaceSettingsFetched,
        isSuccess: workspaceSettingsSuccess,
        refetch: refetchWorkspace,
        isError: workspaceSettingsError,
    } = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const {mutate: updateWorkspaceCfS} = useUpdateWorkspaceSettings({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });

    const {t} = useTranslation('admin');

    useEffect(() => {
        if (!isWorkspacesTestingEnabled && isDataSuccess) {
            setIsAllowProductImprovement(
                (dataOptStatus?.workspaceOptInConfig?.isAllowProductImprovement ?? '') === 'true',
            );
            setIsAllowModelImprovement(
                (dataOptStatus?.workspaceOptInConfig?.isAllowModelImprovement ?? '') === 'true',
            );
            setErrorTagForModelImprovementConsent(false);
            setErrorTagForProductImprovementConsent(false);
        }
    }, [isDataFetched]);

    useEffect(() => {
        if (isWorkspacesTestingEnabled && workspaceSettingsSuccess) {
            if (isWorkspacesTestingEnabled && workspace) {
                setIsAllowProductImprovement(
                    workspace?.freRequirements?.allowProductImprovement ?? false,
                );
                setIsAllowModelImprovement(
                    workspace?.freRequirements?.allowModelImprovement ?? false,
                );
            }
            setErrorTagForModelImprovementConsent(false);
            setErrorTagForProductImprovementConsent(false);
        }
    }, [workspaceSettingsFetched]);

    useEffect(() => {
        if (isDataError) {
            setErrorTagForProductImprovementConsent(true);
            setErrorTagForModelImprovementConsent(true);
        }
    }, [isDataError]);

    useEffect(() => {
        if (isWorkspacesTestingEnabled && workspaceSettingsError) {
            setErrorTagForProductImprovementConsent(true);
            setErrorTagForModelImprovementConsent(true);
        }
    }, [workspaceSettingsError]);

    const updatePreferences = (
        preferences: DataAndPrivacyDetails,
        setUpdateConsent: React.Dispatch<React.SetStateAction<CustomSwitchTransition>>,
        setErrorTag: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
        setUpdateConsent({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        if (isWorkspacesTestingEnabled && workspace) {
            const workspaceSettings: WorkspaceSettings = {
                ...workspace,
                workspaceId: workspace?.workspaceId ?? '',
                tenantId: workspace?.tenantId ?? '',
                freRequirements: {
                    allowModelImprovement: preferences.isAllowModelImprovement,
                    allowProductImprovement: preferences.isAllowProductImprovement,
                },
            };
            updateWorkspaceCfS(
                {
                    workspaceSettings: workspaceSettings,
                    targetWorkspace: dataOptStatus,
                },
                {
                    onSuccess: (data) => {
                        setUpdateConsent({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                        setIsAllowProductImprovement(preferences.isAllowProductImprovement);
                        setIsAllowModelImprovement(preferences.isAllowModelImprovement);
                        setErrorTag(false);
                        refetchWorkspace();
                    },
                    onError: () => {
                        setUpdateConsent({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                        setErrorTag(true);
                    },
                },
            );
        } else {
            updateWorkspace(
                {
                    name: workspaceName ?? '',
                    workspaceOptInConfig: {
                        isAllowProductImprovement: preferences.isAllowProductImprovement
                            ? 'true'
                            : 'false',
                        isAllowModelImprovement: preferences.isAllowModelImprovement
                            ? 'true'
                            : 'false',
                    },
                },
                {
                    onSuccess: (data) => {
                        setUpdateConsent({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                        setIsAllowProductImprovement(
                            (data?.workspaceOptInConfig?.isAllowProductImprovement ?? '') ===
                                'true',
                        );
                        setIsAllowModelImprovement(
                            (data?.workspaceOptInConfig?.isAllowModelImprovement ?? '') === 'true',
                        );
                        setErrorTag(false);
                    },
                    onError: () => {
                        setUpdateConsent({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                        setErrorTag(true);
                    },
                },
            );
        }
    };

    const updateProductImprovement = (isAllowProductImprovement: boolean) => {
        updatePreferences(
            {
                isAllowModelImprovement,
                isAllowProductImprovement: isAllowProductImprovement || false,
            },
            setProductImprovementConsent,
            setErrorTagForModelImprovementConsent,
        );
    };

    const updateModelImprovement = (isAllowModelImprovement: boolean) => {
        updatePreferences(
            {
                isAllowModelImprovement: isAllowModelImprovement || false,
                isAllowProductImprovement,
            },
            setModelImprovementConsent,
            setErrorTagForProductImprovementConsent,
        );
    };

    return (
        <>
            <h2 className={classes.subtitle}>
                {t('ownerSettings.organizationData.SectionHeading')}
            </h2>
            <div className={mergeClasses(classes.paragraphContent)}>
                {isWorkspacesTestingEnabled && workspace
                    ? t('ownerSettings.WorkspacesOrganizationData.InfoContent')
                    : t('ownerSettings.organizationData.InfoContent')}{' '}
                <Link
                    data-test-id="privacy-and-data-security-link"
                    href={MedeinaVariables.PrivacyAndDataSecurity}
                    target="_blank"
                >
                    {t('ownerSettings.organizationData.PrivacyAndSecurity')}
                </Link>
            </div>
            {disableSettingsUpdate && <MSManagedBanner />}

            <div className={classes.dataSwitcher}>
                <div>
                    <ProgressiveSwitch
                        data-test-id="data-sharing-switch"
                        label={t('ownerSettings.organizationData.ImproveSecuritySwitchLabel')}
                        labelPosition="before"
                        aria-label={t(
                            'ownerSettings.organizationData.ariaLabel.ProductImprovement',
                        )}
                        checked={isAllowProductImprovement}
                        onClick={(ev) => updateProductImprovement(ev)}
                        state={updateProductImprovementConsent}
                        disabled={disableSettingsUpdate && !overrideSettings}
                    ></ProgressiveSwitch>
                    {errorTagForModelImprovementConsent && (
                        <div>
                            <div className={classes.errorTag}>
                                <span>
                                    <ErrorIcon filled />
                                </span>
                                <span>{t('ownerSettings.organizationData.ErrorMessage')}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <ProgressiveSwitch
                        data-test-id="ai-model-training-switch"
                        label={t('ownerSettings.organizationData.TrainSecurityCopilotSwitchLabel')}
                        labelPosition="before"
                        aria-label={t('ownerSettings.organizationData.ariaLabel.ModelTraining')}
                        checked={isAllowModelImprovement}
                        onClick={(ev) => updateModelImprovement(ev)}
                        state={updateModelImprovementConsent}
                        disabled={disableSettingsUpdate && !overrideSettings}
                    ></ProgressiveSwitch>
                    {errorTagForProductImprovementConsent && (
                        <div>
                            <div className={classes.errorTag}>
                                <span>
                                    <ErrorIcon filled />
                                </span>
                                <span>{t('ownerSettings.organizationData.ErrorMessage')}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
