import React, {
    forwardRef,
    useImperativeHandle,
    useState,
    useCallback,
    useMemo,
    useRef,
    useEffect,
    useLayoutEffect,
} from 'react';
import {DialogBody, DialogContent, useFocusFinders} from '@fluentui/react-components';
import useClasses from '@/components/sections/workspaces/form/WorkspaceForm.styles';
import TabbedDialogTitle from './DialogTitle';
import {DialogViewInputs, MultiViewDialogProps} from './Dialog.types';

/** A helper component that manages multiple views for Dialogs and more specifically handles reflow
 * The component needs the configuration of the different views that it will support and the corresponding views that they should show
 * Each view should independently manage the full contents of DialogSurface: DialogBody
 */
const MultiViewDialog = forwardRef((props: MultiViewDialogProps, ref) => {
    const {onClose, className, viewConfiguration, mode} = props;
    const classes = useClasses();
    const {findFirstFocusable} = useFocusFinders();
    const dialogBodyRef = useRef<HTMLDivElement>(null);
    const dialogContentRef = useRef<HTMLDivElement>(null);
    const dialogHeaderRef = useRef<HTMLDivElement>(null);

    // manage the screen ready state to deal with reflow buttons appearing and disappearing
    // as the screen size changes and the component mounts
    const [screenReady, setScreenReady] = useState(false);

    const firstTab = viewConfiguration?.[0]?.value;
    const [selectedView, setSelectedView] = useState<string>(firstTab);
    const [viewData, setViewData] = useState<any>(null);
    const [previousView, setPreviousView] = useState<string | null>(null);
    const [previousViewData, setPreviousViewData] = useState<any>(null);

    const handleViewChange = useCallback(
        (viewValue: string, data?: any) => {
            // check that the passed in string is a view that exists in the configuration
            if (!viewConfiguration.find((view) => view.value === viewValue)) {
                console.error(
                    'The view you are trying to select does not exist in the configuration',
                );
                return;
            }
            setPreviousView(selectedView);
            setPreviousViewData(viewData);
            setSelectedView(viewValue);
            setViewData(data);
        },
        [selectedView, viewData, viewConfiguration],
    );

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const fallbackFocus = useCallback(
        (nextElement: string) => {
            // a switch of potential focusable elements
            // if the dialog content is not focusable we will focus on the close button
            let element: HTMLElement | null | undefined;
            switch (nextElement) {
                case 'dialogContent':
                    element = findFirstFocusable(dialogContentRef?.current as HTMLElement);
                    break;
                case 'dialogBody':
                    element = findFirstFocusable(dialogBodyRef?.current as HTMLElement);
                    break;
                case 'dialogHeader':
                default:
                    element = findFirstFocusable(dialogHeaderRef?.current as HTMLElement);
            }
            // check if we have a focusable element
            if (element) {
                element.focus();
                // check that the element now has focus or else start the fallback focus behavior
                if (document.activeElement !== element) {
                    fallbackFocus(nextElement);
                }
            } else {
                // if we don't call the fallback again with the next element depending on the order
                if (nextElement === 'dialogContent') {
                    fallbackFocus('dialogBody');
                } else if (nextElement === 'dialogBody') {
                    fallbackFocus('dialogHeader');
                }
            }
        },
        [findFirstFocusable],
    );

    const updateFocus = useCallback(() => {
        // check if the active element is inside the dialog content
        const activeElement = document.activeElement;
        if (dialogContentRef.current?.contains(activeElement)) {
            // if the active element is inside the dialog content we don't want to change focus
            return;
        } else if (dialogBodyRef.current?.contains(activeElement)) {
            // if the active element is inside the dialog body we don't want to change focus
            return;
        }
        // if we haven't returned by this point it means the active element is not inside the dialog
        // we will attempt to focus on the dialog body first
        fallbackFocus('dialogContent');

        if (selectedView) {
            if (dialogContentRef.current) {
                fallbackFocus('dialogContent');
            }
        } else {
            if (dialogBodyRef.current) {
                fallbackFocus('dialogBody');
            }
        }
    }, [selectedView, fallbackFocus]);

    // For accessibility, we need to focus on the first focusable element in the dialog body or tab list
    // depending on the screen size and the selected view
    // We need to do this every time the selected view changes
    useEffect(() => {
        if (!screenReady) {
            return;
        }
        updateFocus();

        // only update when the selected view changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedView, screenReady]);

    useLayoutEffect(() => {
        // This will be executed after the component mounts, indicating the initial render is complete
        setScreenReady(true);
    }, []); // Empty dependency array ensures this runs once on mount

    const activeView = useMemo(
        () => viewConfiguration.find((view) => view.value === selectedView),
        [selectedView, viewData, viewConfiguration],
    );

    const viewInput: DialogViewInputs = useMemo(
        () => ({
            setView: handleViewChange,
            onClose,
            onBack: () => {
                // if we have a previous view we will go back to it
                // but only if it's not the same as the current view to avoid infinite loops
                if (previousView && previousView !== selectedView) {
                    handleViewChange(previousView);
                }
            },
            data: viewData,
            previousData: previousViewData,
            headerRef: dialogHeaderRef,
            bodyRef: dialogBodyRef,
            contentRef: dialogContentRef,
        }),
        [handleViewChange, onClose, previousView, previousViewData, selectedView, viewData],
    );

    useImperativeHandle(ref, () => (ref ? viewInput : {}));

    const renderActiveView = useCallback(() => {
        if (activeView) {
            return activeView.view(viewInput);
        }
    }, [activeView, viewInput]);

    const renderOnlyBody = activeView?.renderOnlyBody;

    const titleIsString = typeof activeView?.title === 'string';
    const stringTitle = titleIsString ? activeView?.title : '';
    return activeView ? (
        <>
            {!renderOnlyBody &&
                (titleIsString ? (
                    <TabbedDialogTitle
                        title={stringTitle as string}
                        showBackButton={Boolean(activeView.onBackView)}
                        onBackButtonClick={() =>
                            activeView.onBackView && handleViewChange(activeView.onBackView)
                        }
                        onCloseButtonClick={
                            activeView.onCloseButtonClick
                                ? activeView.onCloseButtonClick
                                : handleClose
                        }
                        headerRef={dialogHeaderRef}
                    />
                ) : (
                    <TabbedDialogTitle {...(activeView.title as any)} headerRef={dialogHeaderRef} />
                ))}
            <DialogBody
                className={
                    mode === 'duplicate' ? classes.containerDuplicate : classes.containerCreate
                }
                ref={dialogBodyRef}
            >
                {renderOnlyBody ? (
                    renderActiveView()
                ) : (
                    <DialogContent
                        ref={dialogContentRef}
                        data-testid={`tabbed-dialog-content-${activeView.value.replace(' ', '-')}`}
                    >
                        {renderActiveView()}
                    </DialogContent>
                )}
            </DialogBody>
        </>
    ) : null;
});

MultiViewDialog.displayName = 'MultiViewDialog';

export default MultiViewDialog;
