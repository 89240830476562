import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentDrawer.styles';
import {StatusLabel} from '@sfe/react-status-label';
import {
    Button,
    DrawerHeader,
    DrawerBody,
    DrawerHeaderTitle,
    Spinner,
} from '@fluentui/react-components';
import {Dismiss24Regular, Flow24Regular} from '@fluentui/react-icons';
import {formatLocaleDate as format} from '@/util/locale';
import {AgentTriggerRun} from '@/api/agents';
import {useNavigate} from 'react-router-dom';
import AgentEvaluationResults from './AgentEvaluationResults';
import {
    useGetAgentTriggerRun,
    useGetAgentTriggerExecution,
    Agent,
    AgentScopeTypes,
} from '@/api/agents';
import AgentRunInputHeader from './AgentRunInputHeader';

interface AgentRunDrawerContentProps {
    agent: Agent;
    selectedRun: AgentTriggerRun | undefined;
    onClose: () => void;
    hasInputs: boolean;
}

export default function AgentRunDrawerContent(props: AgentRunDrawerContentProps) {
    const {agent, selectedRun, onClose, hasInputs} = props;
    const classes = useClasses();

    const {t} = useTranslation(['common', 'agents']);
    const navigate = useNavigate();

    const {
        data: triggerRun,
        isLoading: isTriggerRunLoading,
        isError: isTriggerRunError,
    } = useGetAgentTriggerRun({
        agentId: agent.agentId,
        triggerName: agent.triggers?.[0]?.name,
        runId: selectedRun?.runId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    const {
        data: triggerExecution,
        isLoading: isTriggerExecutionLoading,
        isError: isTriggerExecutionError,
    } = useGetAgentTriggerExecution({
        agentId: agent.agentId,
        triggerName: agent.triggers?.[0]?.name,
        executionId: triggerRun?.executionId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    const isLoading = useMemo(() => {
        return isTriggerRunLoading || (triggerRun?.executionId && isTriggerExecutionLoading);
    }, [isTriggerExecutionLoading, isTriggerRunLoading, triggerRun]);

    const isError = useMemo(() => {
        return isTriggerRunError || (triggerRun?.executionId && isTriggerExecutionError);
    }, [isTriggerExecutionError, isTriggerRunError, triggerRun]);

    const isFailedOrPendingRun = useMemo(() => {
        return (
            !triggerRun?.sessionId ||
            !triggerRun?.evaluationId ||
            !triggerRun?.promptId ||
            triggerRun?.status === 'Failed'
        );
    }, [triggerRun]);

    if (!selectedRun) return null;

    return (
        <>
            <DrawerHeader className={classes.drawerHeader} data-testid={'agent-drawer-header'}>
                <DrawerHeaderTitle
                    action={
                        <div className={classes.drawerActions}>
                            {!isFailedOrPendingRun && !isLoading && triggerRun && (
                                <Button
                                    icon={<Flow24Regular />}
                                    onClick={() => {
                                        navigate(`/sessions/${triggerRun.sessionId}`);
                                    }}
                                    appearance="primary"
                                >
                                    {t('agents:Agent.Drawer.Header')}
                                </Button>
                            )}
                            <Button
                                appearance="subtle"
                                aria-label={t('common:ButtonLabels.Close')}
                                icon={<Dismiss24Regular />}
                                data-testid={'agent-drawer-close-button'}
                                onClick={onClose}
                            />
                        </div>
                    }
                >
                    {!isLoading &&
                        triggerRun?.startTime &&
                        format(triggerRun.startTime, 'M/d/y, h:mm a')}
                </DrawerHeaderTitle>
                {!isLoading && triggerRun && (
                    <div
                        className={classes.drawerHeaderStatus}
                        data-testid={'agent-drawer-header-status'}
                    >
                        <StatusLabel
                            className={classes.statusLabel}
                            status={
                                triggerRun.status === 'Completed'
                                    ? 'success'
                                    : triggerRun.status === 'Failed'
                                    ? 'failed'
                                    : triggerRun.status === 'InProgress'
                                    ? 'inProgress'
                                    : 'pending'
                            }
                            text={
                                triggerRun.status === 'Completed'
                                    ? t('agents:Agent.Completed')
                                    : triggerRun.status === 'Failed'
                                    ? t('agents:Agent.Failed')
                                    : triggerRun.status === 'InProgress'
                                    ? t('agents:Agent.InProgress')
                                    : t('agents:Agent.Pending')
                            }
                        />
                    </div>
                )}
            </DrawerHeader>
            <DrawerBody className={classes.drawerBody} data-testid={'agent-drawer-body'}>
                {!isLoading && !isError && triggerRun ? (
                    <>
                        {hasInputs && (
                            <AgentRunInputHeader
                                fetchSkillInputs={triggerExecution?.fetchSkillInputs}
                            />
                        )}
                        {!isFailedOrPendingRun ? (
                            <AgentEvaluationResults
                                sessionId={triggerRun.sessionId}
                                evaluationId={triggerRun.evaluationId}
                                promptId={triggerRun.promptId}
                            />
                        ) : (
                            <div className={classes.error}>
                                <StatusLabel
                                    status={triggerRun?.status === 'Failed' ? 'failed' : 'pending'}
                                    text={
                                        triggerRun?.status === 'Failed'
                                            ? t('agents:Agent.Drawer.Failed')
                                            : t('agents:Agent.Drawer.Pending')
                                    }
                                />
                            </div>
                        )}
                    </>
                ) : !isLoading && isError ? (
                    <div className={classes.error} data-testid="agent-run-error">
                        <StatusLabel status="failed" text={t('agents:Agent.Drawer.RunError')} />
                    </div>
                ) : (
                    <Spinner
                        labelPosition="below"
                        label={t('agents:Agent.Drawer.RunLoading')}
                        data-testid="agent-run-loading-spinner"
                        className={classes.loading}
                    />
                )}
            </DrawerBody>
        </>
    );
}
