import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
    },
    drawerResults: {
        ...shorthands.padding(tokens.spacingHorizontalL, 0),
    },
    drawerBodyHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    drawerBodyHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    drawerBodyHeaderActions: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
        alignItems: 'center',
    },
    drawerBodyHeaderActionsDivider: {
        height: '10px',
    },
    error: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
});
