import {shorthands, makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    containerDuplicate: {
        height: '710px',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorNeutralBackground5} ${tokens.colorTransparentBackground}`,
        '&::-webkit-scrollbar': {
            width: tokens.spacingHorizontalS,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralBackground5,
            ...shorthands.borderRadius('20px'),
        },
    },
    containerCreate: {
        height: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorNeutralBackground5} ${tokens.colorTransparentBackground}`,
        '&::-webkit-scrollbar': {
            width: tokens.spacingHorizontalS,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorTransparentBackground,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralBackground5,
            ...shorthands.borderRadius('20px'),
        },
    },
    dialogRoot: {
        maxWidth: '670px !important',
    },
    root: {
        maxWidth: '600px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('28px'),
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: tokens.spacingVerticalXXXL,
    },
    leftActions: {
        marginRight: 'auto',
    },
    rightActions: {
        display: 'flex',
        marginLeft: 'auto',
        gap: '0.5rem', // Space between right buttons
    },
    peoplepicker: {
        width: '100%',
    },
    newCapacityLinkStyle: {
        marginLeft: tokens.spacingHorizontalSNudge,
        marginRight: tokens.spacingHorizontalSNudge,
        marginTop: tokens.spacingVerticalSNudge,
    },
    hidden: {
        display: 'none',
    },
});
