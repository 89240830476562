import {
    Button,
    Label,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
    Tree,
    TreeItem,
    TreeItemLayout,
} from '@fluentui/react-components';
import useClasses from './ComponentsSideMenu.styles';
import {AddIcon, HexagonSparkleIcon, LibraryIcon, SkillIcon} from '@/components/ui/icons';
import {useEffect, useState} from 'react';
import {
    ApiSkill,
    ComponentsSideMenuProps,
    YamlSkill,
    YamlSkillGroup,
} from './ComponentsSideMenu.types';
import {useTranslation} from 'react-i18next';
import * as yaml from 'js-yaml';

export default function ComponentsSideMenu({
    parsedYaml,
    selectedComponent,
    onComponentsSelection,
}: ComponentsSideMenuProps) {
    const classes = useClasses();
    const [currentComponent, setCurrentComponent] = useState<string>(selectedComponent ?? '');
    const [agentsList, setAgentsList] = useState<YamlSkill[]>([]);
    const [actionsList, setActionsList] = useState<YamlSkill[]>([]);
    const [apiSpecUrl, setApiSpecUrl] = useState<any>(null);
    const {t} = useTranslation('pluginBuilder');
    const emptySkill: YamlSkill = {
        Name: 'newskill',
        DisplayName: `new skill`,
        Description: 'placeholder skill',
    };
    const emptyAgent: YamlSkill = {
        Name: 'newagent',
        DisplayName: `new agent`,
        Description: 'placeholder agent',
    };

    const handleAgentCreation = () => {
        return () => {
            const newAgentsList = [...agentsList];
            newAgentsList.push(emptyAgent);
            setAgentsList(newAgentsList);
        };
    };

    const handleSkillCreation = () => {
        return () => {
            const newActionsList = [...actionsList];
            newActionsList.push(emptySkill);
            setActionsList(newActionsList);
        };
    };

    const handleComponentSelection = (component: string) => {
        setCurrentComponent(component);
        onComponentsSelection?.(component);
    };

    // Configures the skill lists based on the SkillGroup format
    const formatYamlSkills = (skillGroup: YamlSkillGroup) => {
        try {
            let newSkills: YamlSkill[] = [];

            if (skillGroup.Format !== 'API') {
                newSkills = skillGroup.Skills.map((skill: YamlSkill) => ({
                    ...skill,
                    Format: skillGroup.Format,
                }));
            }

            if (skillGroup.Format === 'Agent') {
                setAgentsList((prevAgents) => [...prevAgents, ...newSkills]);
            } else if (skillGroup.Format === 'API') {
                setApiSpecUrl(skillGroup.Settings?.OpenApiSpecUrl);
            } else {
                setActionsList((prevActions) => [...prevActions, ...newSkills]);
            }
        } catch (e) {
            console.error('Error formatting yaml skills:', e);
        }
    };

    // Fetches the yaml content from OpenApiSpecUrl for API skills
    useEffect(() => {
        const fetchDocument = async () => {
            if (!apiSpecUrl) return;
            try {
                const response = await fetch(apiSpecUrl);
                const text = await response.text();
                const parsedDoc: ApiSkill = yaml.load(text) as ApiSkill;
                const operationIds: {path: string; method: string; operationId: string}[] = [];
                const paths = parsedDoc.paths;
                if (paths) {
                    Object.keys(paths).forEach((path) => {
                        const methods = paths[path];
                        Object.keys(methods).forEach((method) => {
                            const operationId = methods[method]?.operationId;
                            if (operationId) {
                                operationIds.push({path, method, operationId});
                            }
                        });
                    });
                }
                operationIds.map((operation) => {
                    const ApiToYamlSkill: YamlSkill = {
                        Name: operation.operationId ?? '',
                        Description: parsedDoc.info.description ?? '',
                        Format: 'API',
                    };
                    if (!actionsList.includes(ApiToYamlSkill)) {
                        setActionsList((prevActions) => [...prevActions, ApiToYamlSkill]);
                    }
                });
            } catch (e) {
                console.error('Error fetching OpenSpecUrl for API Skill:', e);
            }
        };

        fetchDocument();
    }, [apiSpecUrl, parsedYaml]);

    // Populates the agents and actions list
    useEffect(() => {
        if (parsedYaml) {
            setAgentsList([]);
            setActionsList([]);
            const skillGroups = parsedYaml.SkillGroups ?? {};
            if (Array.isArray(skillGroups) && skillGroups.length > 0) {
                skillGroups.map((skillGroup: YamlSkillGroup) => {
                    formatYamlSkills(skillGroup);
                });
            }
        }
    }, [parsedYaml]);

    return (
        <div className={classes.container}>
            <div className={classes.componentsTitle}>
                <Label aria-label={t('ComponentExplorer')} className={classes.componentsLabel}>
                    {t('ComponentExplorer')}
                </Label>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            data-testid="add-component-button"
                            appearance="primary"
                            className={classes.createButton}
                            icon={<AddIcon className={classes.addIcon} />}
                        >
                            {t('AddButton')}
                        </Button>
                    </MenuTrigger>
                    <MenuPopover role="menu" className={classes.menuPopover}>
                        <MenuList>
                            <MenuItem
                                aria-label={t('CreateAgent')}
                                role="menuitem"
                                icon={<HexagonSparkleIcon />}
                                onClick={handleAgentCreation()}
                            >
                                {t('CreateAgent')}
                            </MenuItem>
                            <MenuItem
                                aria-label={t('CreateSkill')}
                                role="menuitem"
                                icon={<SkillIcon />}
                                onClick={handleSkillCreation()}
                            >
                                {t('CreateSkill')}
                            </MenuItem>
                            {/*<MenuItem icon={<LibraryIcon />} onClick={handleKnowledgeCreation()}>
                            Create Knowledge Object
                        </MenuItem>*/}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
            <div>
                <Tree aria-label="components-list">
                    <TreeItem itemType="branch">
                        <TreeItemLayout
                            data-testid="agents-title"
                            aria-label={t('AgentsTitle')}
                            className={classes.branches}
                        >
                            {t('AgentsTitle')} ({agentsList.length})
                        </TreeItemLayout>
                        <Tree>
                            {agentsList.map((agent, index) => (
                                <TreeItem key={index} itemType="leaf">
                                    <TreeItemLayout
                                        data-testid={`${agent.Name}-tree-item`}
                                        className={mergeClasses(
                                            classes.leaves,
                                            currentComponent === agent.Name
                                                ? classes.currentComponent
                                                : undefined,
                                        )}
                                        iconBefore={
                                            <HexagonSparkleIcon
                                                filled={currentComponent === agent.Name}
                                            />
                                        }
                                        onClick={() =>
                                            handleComponentSelection(agent.Name as string)
                                        }
                                    >
                                        {agent.Name}
                                    </TreeItemLayout>
                                </TreeItem>
                            ))}
                        </Tree>
                    </TreeItem>
                    <TreeItem itemType="branch">
                        <TreeItemLayout
                            data-testid="actions-title"
                            aria-label={t('ActionsTitle')}
                            className={classes.branches}
                        >
                            {t('ActionsTitle')} ({actionsList.length})
                        </TreeItemLayout>
                        <Tree>
                            {actionsList.map((action, index) => (
                                <TreeItem
                                    data-testid={`${action.Name}-tree-item`}
                                    key={index}
                                    itemType="leaf"
                                >
                                    <TreeItemLayout
                                        className={mergeClasses(
                                            classes.leaves,
                                            currentComponent === action.Name
                                                ? classes.currentComponent
                                                : undefined,
                                        )}
                                        iconBefore={
                                            <Label className={classes.actionIcon}>
                                                {action.Format}
                                            </Label>
                                        }
                                        onClick={() =>
                                            handleComponentSelection(action.Name as string)
                                        }
                                    >
                                        {action.Name}
                                    </TreeItemLayout>
                                </TreeItem>
                            ))}
                        </Tree>
                    </TreeItem>
                    {/*<TreeItem itemType='branch'>
                    <TreeItemLayout className={classes.branches} itemType={'branch'}>
                        Knowledge ({knowledgeList.length})
                    </TreeItemLayout>
                    <Tree>
                        {knowledgeList.map((knowledge, index) => (
                            <TreeItem key={index} itemType="leaf">
                                <TreeItemLayout className={classes.leaves} iconBefore={<LibraryIcon/>}>{knowledge}</TreeItemLayout>
                            </TreeItem>
                        ))}
                    </Tree>
                    </TreeItem>*/}
                </Tree>
            </div>
        </div>
    );
}
