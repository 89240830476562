/**
 * Props for the CentralDashboardContainer component.
 *
 * @interface CentralDashboardContainerProps
 * @property {KeyValueFilterDetails[]} copilotExperienceMap - The map of copilot experience details.
 * @property {SelectedSearchFacet[]} searchFilters - The selected search filters.
 * @property {boolean} isLimitedOverageState - Indicates if the overage state is limited.
 * @property {OverageCapacityDisplayData} usageDisplayDetails - The display details for overage capacity.
 * @property {WorkspaceDisplayDetails} workspaceDetails - The details of the workspace.
 * @property {UsageParametersDisplayConfiguration} displayConfiguration - The configuration for usage parameters display.
 * @property {boolean} enableBillingInCurrentHour - Indicates if billing is enabled in the current hour.
 * @property {UsageDashboardDateFilterDropdownProps} dateFilterOptions - The options for the date filter dropdown.
 * @property {DashboardFilterContainerProps} filterContainerProps - The props for the dashboard filter container.
 * @property {SelectedFilterPillConfiguration} filterPillsOptions - The configuration for selected filter pills.
 * @property {boolean} isEvaluationAggregateDataLoading - Indicates if the evaluation aggregate data is loading.
 * @property {boolean} isOverageEnabled - Indicates if overage is enabled.
 */

/**
 * CentralDashboardContainer component.
 *
 * @param {CentralDashboardContainerProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities';
import BottomRow from './BottomRow';
import TopRow from './TopRow';
import {
    OverageCapacityDisplayData,
    WorkspaceDisplayDetails,
    UsageParametersDisplayConfiguration,
    UsageDashboardDateFilterDropdownProps,
    DashboardFilterContainerProps,
    SelectedFilterPillConfiguration,
} from '../UsageDashboard.types';

interface CentralDashboardContainerProps {
    copilotExperienceMap: KeyValueFilterDetails[];
    searchFilters: SelectedSearchFacet[];
    isLimitedOverageState: boolean;
    usageDisplayDetails: OverageCapacityDisplayData;
    workspaceDetails: WorkspaceDisplayDetails;
    displayConfiguration: UsageParametersDisplayConfiguration;
    dateFilterOptions: UsageDashboardDateFilterDropdownProps;
    filterContainerProps: DashboardFilterContainerProps;
    filterPillsOptions: SelectedFilterPillConfiguration;
    isEvaluationAggregateDataLoading: boolean;
    isOverageEnabled: boolean;
    selectedCapacity?: string;
}

export default function CentralDashboardContainer(props: CentralDashboardContainerProps) {
    return (
        <>
            <TopRow {...props}></TopRow>
            <BottomRow
                displayConfiguration={props.displayConfiguration}
                isOverageEnabled={props.isOverageEnabled}
                selectedCapacity={props.selectedCapacity}
            ></BottomRow>
        </>
    );
}
