import {AgentDefinition, Agent} from '@/api/agents';
import {useCallback, useState} from 'react';

import useClasses from './AgentLibraryCard.styles';
import {useTranslation} from 'react-i18next';
import {AgentSummaryCard, StatusLabelProps} from '@sfe/react';
import AgentDetailDialog from '../AgentDetailDialog/AgentDetailDialog';
import {useNavigate} from 'react-router-dom';
import {Body1Strong} from '@fluentui/react-components';
import useAgentStatus from '../useAgentStatus';

export default function AgentLibraryCard({
    agentDefinition,
    agent,
}: {
    agentDefinition: AgentDefinition;
    agent: Agent | undefined;
}) {
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');
    const navigate = useNavigate();

    const {getStatus} = useAgentStatus(agent, agentDefinition);

    // Hardcoded image path for Microsoft Agent Persona for now
    const microsoftAgentPersonalimagePath = '/images/icons/MicrosoftAgentPersona.svg';

    const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);

    const handleViewDetails = () => {
        setIsDetailDialogOpen(true);
    };

    const handleCloseDetails = () => {
        setIsDetailDialogOpen(false);
    };

    const handleGoToAgent = (agentId: string) => {
        navigate(`/agents/${agentId}`);
    };

    if (!agentDefinition) {
        return null;
    }

    return (
        <>
            {agentDefinition && (
                <>
                    <AgentSummaryCard
                        data-testid="agent-summary-card"
                        className={classes.agentCard}
                        header={{
                            avatar: {
                                initials: null,
                                image: {
                                    className: classes.agentCardImage,
                                    src: microsoftAgentPersonalimagePath,
                                },
                            },
                            name: agentDefinition.displayName,
                            primaryText: agentDefinition.displayName || agentDefinition.name,
                            secondaryText: agentDefinition.publisher,
                            status: getStatus(),
                        }}
                        body={{
                            className: classes.agentCardDescription,
                            children: agentDefinition.description,
                        }}
                        footer={{
                            primaryAction: {
                                onClick: () => {
                                    if (agent) {
                                        handleGoToAgent(agent.agentId);
                                    } else {
                                        handleViewDetails();
                                    }
                                },
                                children: agent
                                    ? tAgents('AgentLibrary.GoToAgent')
                                    : tAgents('AgentLibrary.ViewDetails'),
                            },
                        }}
                    />

                    <AgentDetailDialog
                        agentDefinition={agentDefinition}
                        open={isDetailDialogOpen}
                        onClose={handleCloseDetails}
                    />
                </>
            )}
        </>
    );
}
