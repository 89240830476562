import {
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Link,
} from '@fluentui/react-components';
import React, {useEffect, useRef, useState} from 'react';
import {WorkspaceFormProps} from './WorkspaceForm.types';
import useClasses from './WorkspaceForm.styles';
import CapacitySelectField from './CapacitySelect';
import StorageLocation from './StorageLocationField';
import WorkspaceName from './WorkspaceNameField';
import ModelImprovement from './ModelImprovementField';
import ProductImprovement from './ProductImprovementField';
import TermsConditions from './TermsConditionsField';
import {useTranslation} from 'react-i18next';
import {
    useGetWorkspaces,
    useGetWorkspaceSettings,
    usePutWorkspace,
    Workspace,
} from '@/api/workspaces';
import {CreateGeoWorkspaceRequest, WorkspaceSettings} from '@/api/workspaces/workspaces.types';
import {useFeatureFlag, useUserState} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useCreateWorkspaceSettings from '@/api/workspaces/useCreateWorkspaceSettings';
import {FormErrorMessageBar} from './FormErrorMessageBar';
import {usePolicyManager} from '@/components/admin/rbac';
import {PolicyWorkspace, RoleMembers} from '@/api/rbac/rbac.types';
import {RoleObjectIdData} from '@/components/admin/rbac/rbac.types';
import CreateCapacity from '@/components/CreateCapacity';
import useCreateWorkspace from '@/api/workspaces/useCreateWorkspace';
import WorkspacesLoadingOverlay from '../ManageWorkspaces/WorkspacesLoadingOverlay';
import {useGetAccount} from '@/api/accounts';

import {RoleAssignment} from '@/components/admin';
import {FieldValidationState} from '@/components/ui/forms';
import {useGetRoleMembers, useUpdateWorkspacePolicyById} from '@/api/rbac';
function Create(props: WorkspaceFormProps) {
    const {
        mode,
        onCancel,
        onClose,
        onDelete,
        onCreate,
        onDuplicate,
        workspace,
        hideCapacitySelectField: hideCapacitySelectField,
        onWorkspaceCreated,
    } = props;
    const classes = useClasses();
    const {t} = useTranslation();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    // field state values
    const [workspaceName, setWorkspaceName] = useState(workspace?.name || '');

    const {
        data: accountResponse,
        error: accountError,
        isLoading: accountLoading,
        isFetched: accountFetched,
        isSuccess: accountIsSuccessful,
        isError: accountIsError,
    } = useGetAccount();
    const storedDataStorageLocation = React.useMemo(() => {
        return accountResponse?.pods?.find((pod) => pod.podId === workspace?.path?.split('/')[1]);
    }, [accountFetched]);
    const [dataStorageLocation, setDataStorageLocation] = useState(
        storedDataStorageLocation?.geo || '',
    );
    const [capacity, setCapacity] = useState('');
    const [createdSuccess, setCreatedSuccess] = useState(false);
    const [modelImprovement, setModelImprovement] = useState(true);
    const [productImprovement, setProductImprovement] = useState(true);
    const [termsConditions, setTermsConditions] = useState(false);
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(false);
    // multi-workspaces
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {data: workspaceCfS, isFetched: workspacesCfSFetched} = useGetWorkspaceSettings(
        workspace,
        {
            enabled: Boolean(isWorkspacesTestingEnabled),
        },
    );
    const {mutate: createWorkspaceSettings} = useCreateWorkspaceSettings();
    const {mutate: createWorkspace, isLoading, isError, isSuccess} = usePutWorkspace();

    const handleCapacityCreated = (newCapacityName: string) => {
        setCapacity(newCapacityName);
    };
    const handleCreateWorkspace = useCreateWorkspace();

    useEffect(() => {
        // if we are in duplicate mode, we should populate the fields with the workspace data
        if (mode === 'duplicate' && workspaceCfS) {
            // prefill the fields with the workspace data
            if (workspacesCfSFetched) {
                setModelImprovement(
                    workspaceCfS?.freRequirements?.allowModelImprovement ? true : false,
                );
                setProductImprovement(
                    workspaceCfS?.freRequirements?.allowProductImprovement ? true : false,
                );
            }
        }
    }, []);

    const {data: workspacesData, error: workspaceError} = useGetWorkspaces();
    const {tenant: userTenant} = useUserState();
    const [overlay, setOverlay] = useState<boolean>(false);

    const handleFormSubmit = async () => {
        setOverlay(true);
        try {
            // first check if capacity selected is already in use
            const attachedWorkspace = workspacesData?.value.find(
                (workspace: Workspace) => workspace.capacity?.referenceName === capacity,
            );
            if (attachedWorkspace) {
                // remove capacity from attached workspace using putWorkspace call
                createWorkspace(
                    {
                        request: {
                            ...attachedWorkspace,
                            name: attachedWorkspace.name,
                            capacity: null,
                        },
                        targetWorkspace: attachedWorkspace,
                    },
                    {
                        onSuccess: (data) => {
                            console.log(
                                'Successfully disconnected capacity ' +
                                    capacity +
                                    ' from ' +
                                    attachedWorkspace?.name +
                                    ' workspace',
                            );
                        },
                        onError: () => {
                            console.log(
                                'Error disconnecting capacity ' +
                                    capacity +
                                    ' from ' +
                                    attachedWorkspace?.name +
                                    ' workspace',
                            );
                            setOverlay(false);
                        },
                    },
                );
            }
            // create new workspace
            let workspace: CreateGeoWorkspaceRequest;
            if (hideCapacitySelectField) {
                // omit capacity
                workspace = {
                    name: workspaceName,
                    dataStorageLocation: dataStorageLocation,
                    workspaceOptInConfig: {
                        isAllowModelImprovement: 'false',
                        isAllowProductImprovement: 'false',
                    },
                };
            } else {
                workspace = {
                    name: workspaceName,
                    capacity: {type: 'CapacityReference', referenceName: capacity},
                    dataStorageLocation: dataStorageLocation,
                    workspaceOptInConfig: {
                        isAllowModelImprovement: 'false',
                        isAllowProductImprovement: 'false',
                    },
                };
            }
            const newWorkspace = await handleCreateWorkspace(workspace).then(
                (response) => {
                    return response as Workspace;
                },
                (error) => {
                    console.error('Error creating workspace', error);
                    setIsMessageBarVisible(true);
                    setOverlay(false);
                },
            );
            if (!newWorkspace) {
                console.error('Error creating workspace');
                setIsMessageBarVisible(true);
                setOverlay(false);
                return;
            }
            // create workspace settings
            const workspaceCfS: WorkspaceSettings = {
                workspaceId: workspaceName,
                tenantId: userTenant?.tenantId,
                freRequirements: {
                    allowModelImprovement: modelImprovement,
                    allowProductImprovement: productImprovement,
                },
            };
            createWorkspaceSettings(
                {
                    workspaceSettings: workspaceCfS,
                    targetWorkspace: newWorkspace,
                },
                {
                    onSuccess: () => {
                        if (mode === 'duplicate') {
                            setCreatedSuccess(true);
                        } else {
                            console.log('Workspace settings created');
                            onClose?.();
                            setIsMessageBarVisible(false);
                            setOverlay(false);
                            onCreate?.();
                        }
                    },
                    onError: (error) => {
                        console.error('Error creating workspace settings', error);
                        setOverlay(false);
                        setIsMessageBarVisible(true);
                    },
                },
            );
            onWorkspaceCreated?.(workspaceName);
        } catch (error) {
            console.error('Error creating workspace', error);
            setOverlay(false);
            setIsMessageBarVisible(true);
        }
    };
    // for adding role members
    const [copiedOwnerRoleMembers, setCopiedOwnerRoleMembers] = useState<RoleMembers>();
    const [copiedContributorRoleMembers, setCopiedContributorRoleMembers] = useState<RoleMembers>();
    // on final form submit this should overwrite all preexisting calls and use the new duplicated workspace to call policy
    const {
        data: dataShareResponse,
        isSuccess: dataShareSuccessful,
        isFetched: dataShareFetched,
    } = useGetRoleMembers(workspaceName, undefined, {
        enabled: createdSuccess,
        refetchOnMount: true,
    });

    // for duplicate, this is getting the workspace policy of workspace being duplicated
    const {
        data: oldWorkspacePolicy,
        isLoading: oldWorkspacePolicyLoading,
        isFetched: oldWorkspacePolicyFetched,
        isSuccess: oldWorkspacePolicyIsSuccessful,
        isError: oldWorkspacePolicyError,
        isRefetching: oldWorkspacePolicyRefetching,
    } = useGetRoleMembers(workspace?.name || '', undefined, {
        enabled: !!workspace && !createdSuccess,
    });
    const [policyManager, setPolicyManager] = useState<ReturnType<typeof usePolicyManager> | null>(
        null,
    );
    const oldPolicyManager = usePolicyManager({
        workspacePolicy: oldWorkspacePolicy as PolicyWorkspace,
        workspaceName: oldWorkspacePolicy?.properties?.entity?.referenceName as string,
    });
    const newPolicyManager = usePolicyManager({
        workspacePolicy: dataShareResponse as PolicyWorkspace,
        workspaceName: dataShareResponse?.properties?.entity?.referenceName as string,
    });
    const {mutateAsync: updateWorkspacePolicy} = useUpdateWorkspacePolicyById();
    useEffect(() => {
        if (dataShareResponse && createdSuccess) {
            setPolicyManager(() => newPolicyManager);
        }
    }, [dataShareSuccessful, dataShareFetched, newPolicyManager]);

    useEffect(() => {
        if (policyManager && createdSuccess) {
            addRoleMembers();
            setCreatedSuccess(false); // we only want this to run once
        }
    }, [policyManager]);
    const addRoleMembers = async () => {
        const mapRoleMembers = (roleMembers: RoleMembers | undefined) => {
            const userIds: string[] = [];
            const roleIds: string[] = [];
            const groupIds: string[] = [];

            roleMembers?.users?.forEach((user) => {
                if (user.id !== '1pRecommendedRoles') {
                    userIds.push(user.id ?? '');
                } else {
                    policyManager?.togglePartnerRolesCondition(undefined, undefined, true);
                }
            });
            roleMembers?.roles?.forEach((role) => roleIds.push(role.id ?? ''));
            roleMembers?.groups?.forEach((group) => groupIds.push(group.id ?? ''));

            return {User: userIds, Role: roleIds, Group: groupIds};
        };

        const roleObjectIdData: RoleObjectIdData = {
            Owner: mapRoleMembers(copiedOwnerRoleMembers),
            Contributor: mapRoleMembers(copiedContributorRoleMembers),
        };

        if (policyManager) {
            const policyReferenceName = dataShareResponse?.properties?.entity?.referenceName;
            const updatedPolicy = policyManager.updateExistingPolicy(
                roleObjectIdData,
                policyReferenceName as string,
            );
            if (!!updatedPolicy) {
                try {
                    await updateWorkspacePolicy({
                        workspaceName: workspaceName as string,
                        policy: updatedPolicy,
                    });
                    onClose?.();
                    setIsMessageBarVisible(false);
                    setOverlay(false);
                    onDuplicate?.();
                } catch (error) {
                    console.error('Error updating policy', error);
                }
            }
        }
    };

    const primaryButtonLabel =
        mode === 'create' ? tCommon('ButtonLabels.Create') : tCommon('ButtonLabels.Duplicate');
    mode === 'create' ? tCommon('ButtonLabels.Create') : tCommon('ButtonLabels.Duplicate');

    // create capacity
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);
    const newCapacityButtonRef = useRef<HTMLDivElement>(null);
    const [validationState, setValidationState] = useState<FieldValidationState>({});
    // Determine if the create button should be disabled
    const isCreateButtonDisabled =
        !workspaceName || !termsConditions || !dataStorageLocation || validationState.error;

    return (
        <>
            <form>
                {isMessageBarVisible && <FormErrorMessageBar messageTitle={mode.toString()} />}
                <DialogContent className={classes.dialogContent}>
                    <WorkspaceName
                        value={workspaceName}
                        preventDuplicate
                        onChange={(value) => setWorkspaceName(value)}
                        validationState={validationState}
                        setValidationState={setValidationState}
                        required
                    />
                    {!hideCapacitySelectField && (
                        <div ref={newCapacityButtonRef}>
                            <CapacitySelectField
                                value={capacity}
                                onOptionSelect={(capacity) => {
                                    setCapacity(capacity.name);
                                }}
                            />
                            <Link
                                className={classes.newCapacityLinkStyle}
                                onClick={() => {
                                    setIsCreateCapacityDialogOpen(true);
                                }}
                            >
                                {tAdmin('Workspaces.Form.CreateNewCapacityLink')}
                            </Link>
                        </div>
                    )}
                    <StorageLocation
                        value={dataStorageLocation}
                        onOptionSelect={(selectedLocation) =>
                            setDataStorageLocation(selectedLocation)
                        }
                        required
                    />
                    {mode === 'duplicate' && (
                        <RoleAssignment
                            dataShareResponse={oldWorkspacePolicy ?? ({} as PolicyWorkspace)}
                            dataShareLoading={oldWorkspacePolicyLoading}
                            dataShareFetched={oldWorkspacePolicyFetched}
                            dataShareIsSuccessful={oldWorkspacePolicyIsSuccessful}
                            dataShareError={oldWorkspacePolicyError}
                            dataShareRefetching={oldWorkspacePolicyRefetching}
                            policyManager={oldPolicyManager}
                            setCopiedOwnerRoleMembers={setCopiedOwnerRoleMembers}
                            setCopiedContributorRoleMembers={setCopiedContributorRoleMembers}
                        />
                    )}
                    <ProductImprovement
                        value={productImprovement}
                        onChange={(checked) => setProductImprovement(checked)}
                    />
                    <ModelImprovement
                        value={modelImprovement}
                        onChange={(checked) => setModelImprovement(checked)}
                    />
                    <TermsConditions
                        value={termsConditions}
                        onChange={(checked) => setTermsConditions(checked)}
                        required
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div className={classes.rightActions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={props.onClose}>
                                {t('ButtonLabels.Cancel')}
                            </Button>
                        </DialogTrigger>
                        <Button
                            appearance="primary"
                            onClick={handleFormSubmit}
                            disabled={isCreateButtonDisabled}
                            data-testid="create-workspace-button"
                        >
                            {primaryButtonLabel}
                        </Button>
                    </div>
                </DialogActions>
            </form>
            <CreateCapacity
                isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                onCreateCapacityDialogClose={() => {
                    setIsCreateCapacityDialogOpen(false);
                    //newCapacityButtonRef.current?.focus();
                }}
                hideWorkspaceSelectField={true}
                onCapacityCreated={handleCapacityCreated}
            />
            {overlay && (
                <WorkspacesLoadingOverlay
                    label={
                        mode === 'create'
                            ? tAdmin('ManageWorkspaces.CreatingWorkspace')
                            : tAdmin('ManageWorkspaces.DuplicatingWorkspace')
                    }
                />
            )}
        </>
    );
}

export default Create;
