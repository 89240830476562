import React, {useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getParamsFromPath} from '../util';
import {MedeinaRoutesMap} from '../../MedeinaRoutes';
import useCrumbClasses from '../Breadcrumbs.styles';
import AgentBreadcrumb from '@/components/sections/agents/overview/AgentBreadcrumb';
import {Button, mergeClasses} from '@fluentui/react-components';

interface AgentsBreadcrumbProps {
    renderOnlyLast?: boolean;
}

function AgentsBreadcrumbs(props: AgentsBreadcrumbProps) {
    const {renderOnlyLast} = props;
    const classes = useCrumbClasses();
    const location = useLocation();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const label = t('Breadcrumbs.agents');

    const params = useMemo(
        () => getParamsFromPath(MedeinaRoutesMap.agent.path, location.pathname),
        [location],
    );

    return (
        <>
            {!renderOnlyLast && (
                <li className={classes.crumb}>
                    <Button
                        className={mergeClasses(classes.link, classes.linkAgents)}
                        onClick={() => {
                            navigate(MedeinaRoutesMap.agentsLibrary.path);
                        }}
                        aria-label={label}
                        size="medium"
                        appearance="subtle"
                    >
                        {label}
                    </Button>
                </li>
            )}
            {params?.agentId && (
                <>
                    {!renderOnlyLast && (
                        <li role="separator" className={classes.spacer}>
                            /
                        </li>
                    )}
                    <li className={classes.crumb}>
                        <AgentBreadcrumb agentId={params?.agentId} />
                    </li>
                </>
            )}
        </>
    );
}

export default AgentsBreadcrumbs;
