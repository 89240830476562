import {getCurrentTenantId} from '@/util/msal/authConfig';
import useCreateCapacity from './useCreateCapacity';
import {useGetWorkspaceByName, useGetWorkspaces, useGetWorkspacesForAdminFre} from '../workspaces';
import useProvisionAccount from '../accounts/useProvisionAccount';
import useCreateResourceGroup from '../user/useCreateResourceGroup';
import useCreateFirstWorkspace from '../workspaces/useCreateFirstWorkspace';
import useUpdateWorkspace from '../workspaces/useUpdateWorkspace';
import {CrossRegionCompute, OverageState} from './capacities.types';
import MedeinaVariables from '@/util/variables';
import {useGetTenantInfo} from '../tenant';
import {useRegisterProviderWithSubscription} from '.';
import {useGetAccount} from '../accounts';
import {FidelisApiError, FidelisErrorType} from '../api';
import {AccountDetails, ProvisionAccountRequest} from '../accounts/accounts.types';
import MedeinaFeatures, {MedeinaFeatureFlagValue} from '@/util/features';
import useCreateWorkspaceSettings from '../workspaces/useCreateWorkspaceSettings';
import {WorkspaceSettings} from '../workspaces/workspaces.types';
import useCreateWorkspace from '@/api/workspaces/useCreateWorkspace';
import useCreateFreWorkspace from '@/api/workspaces/useCreateFREWorkspace';
import useFeatureFlag from '@/api/user/useFeatureFlag';
import {
    formatWorkspaceToSessionData,
    getCurrentWorkspaceCache,
} from '@/components/sections/workspaces/WorkspacesProvider';

interface SetupAccountAndWorkspaceProps {
    selectedGeo: string;
    workspaceName?: string;
}

interface SetupGeoAccountAndWorkspaceProps extends SetupAccountAndWorkspaceProps {
    workspaceName: string;
}

interface SetupAndProvisionCapacityProps {
    capacityName?: string;
    isNewResourceGroup?: boolean;
    subscription?: string;
    resourceGroup?: string;
    geo?: string;
    region?: string;
    capacityUnits?: number;
    overageState?: OverageState;
    overageAmount?: number;
    isCapacityProvisioned?: boolean;
    isCrossRegionAllowed?: boolean;
    shouldPreLoadWorkspace?: boolean;
    skipWorkspaceAssociation?: boolean;
    onAccountCreationError?: (error: any) => void;
    onCapacityCreationError?: (error: any) => void;
    onWorkspaceProvisionError?: (error: any) => void;
    onWorkspaceMappingError?: (error: any) => void;
    onWorkspaceMappingCompletion?: () => Promise<void>;
    onResourceGroupCreationError?: (error: any) => void;
    onServicePlanCreationError?: (error: any) => void;
    onInitialAccountAndWorkspaceProvisioningSuccess?: () => void;
    onSubscriptionRegistrationError?: (error: any) => void;
    onAccountFetchError?: (error: any) => void;
    isWorkspacesTestingEnabled: MedeinaFeatureFlagValue;
}

// This hook is used to handle the setup and provisioning of capacity and other cosntructs
export default function useSetupAndProvisionFidelisConstructs({
    capacityName,
    isNewResourceGroup,
    subscription,
    resourceGroup,
    geo,
    region,
    capacityUnits,
    overageAmount,
    overageState,
    isCapacityProvisioned,
    isCrossRegionAllowed,
    shouldPreLoadWorkspace,
    skipWorkspaceAssociation,
    isWorkspacesTestingEnabled,
    onInitialAccountAndWorkspaceProvisioningSuccess, // This function is called when the initial account and workspace provisioning is successful
    onAccountCreationError, // This function is called when an error occurs during account creation
    onCapacityCreationError, // This function is called when an error occurs during capacity creation
    onWorkspaceProvisionError, // This function is called when an error occurs during workspace provisioning
    onWorkspaceMappingError, // This function is called when an error occurs during workspace mapping
    onWorkspaceMappingCompletion, // This function is called when workspace mapping is completed
    onResourceGroupCreationError, // This function is called when an error occurs during resource group creation
    onSubscriptionRegistrationError, // This function is called when an error occurs during subscription registration
    onAccountFetchError, // This function is called when an unhandled error occurs during account fetching of accounts
}: SetupAndProvisionCapacityProps) {
    const newWorkspaceName = 'default';
    const PROVISION_ATTEMPT_COUNT = 20;
    const PROVISION_ATTEMPT_INTERVAL = 5000;
    const PROVISION_WORKSPACE_CONFIRM_INTERVAL = 5000;
    const SUBSCRIPTION_REGISTRATION_ATTEMPT_COUNT = 20;
    const SUBSCRIPTION_REGISTRATION_ATTEMPT_INTERVAL = 5000;

    const tenantInfo = useGetTenantInfo();
    const {refetch: getWorkspaceBeforeFre} = useGetWorkspacesForAdminFre({
        enabled: false,
    });

    const {refetch: getAccount} = useGetAccount({
        enabled: false,
    });

    const {data: workspaceInfo, refetch: getWorkspaces} = useGetWorkspaces({
        enabled: !!tenantInfo?.data?.freRequirements?.freRequirementsMet,
        queryKey: ['fre'],
    });
    let isOverageEnabled = useFeatureFlag(MedeinaFeatures.EnableUsageDashboardOverage) as boolean;

    const {mutateAsync: registerSubscriptionWithProvider} = useRegisterProviderWithSubscription();
    const {mutateAsync: mapWorkspace} = useUpdateWorkspace();
    const {mutateAsync: provisionWorkspace} = useCreateFirstWorkspace();
    // multi-workspaces
    const handleCreateWorkspace = useCreateWorkspace();
    const handleCreateFreWorkspace = useCreateFreWorkspace();
    const {mutate: provisionWorkspaceSettings} = useCreateWorkspaceSettings({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const {mutateAsync: createResourceGroup} = useCreateResourceGroup();
    const {mutateAsync: provisionAccount} = useProvisionAccount(getCurrentTenantId());
    const {mutateAsync: createCapacity} = useCreateCapacity(
        subscription || '',
        resourceGroup || '',
        capacityName || '',
    );

    const {refetch: refetchWorkspaceByName} = useGetWorkspaceByName(newWorkspaceName, {
        refetchOnMount: true,
        enabled:
            !!tenantInfo?.data?.freRequirements?.freRequirementsMet || !!shouldPreLoadWorkspace,
    });

    // This function creates a new resource group if it doesn't exist
    const createResourceGroupIfNew = async () => {
        const resourceGroupRequest = {
            location: region || '',
            subscriptionId: subscription || '',
            resourceGroupName: resourceGroup || '',
        };
        try {
            await createResourceGroup(resourceGroupRequest);
        } catch (error) {
            onResourceGroupCreationError?.(error);
        }
    };

    // This function creates a new account
    const createAccountAsync = async () => {
        try {
            await provisionAccount(null);
            if (!isCapacityProvisioned) {
                // Try to register the subscription with the provider. The error handling is done internally
                const registrationResult = await provisionSubscriptionWithProvider();

                if (!registrationResult) {
                    // If the registration failed we return. The
                    return;
                }
                await createCapacityAsync();
            } else {
                await checkAndProvisionWorkspace();
            }
        } catch (error) {
            onAccountCreationError?.(error);
        }
    };

    // This function checks if a workspace exists and provisions a new one if it doesn't
    const checkAndProvisionWorkspace = async () => {
        if ((workspaceInfo?.count ?? 0) > 0 && workspaceInfo?.value?.[0]?.name !== undefined) {
            if (!skipWorkspaceAssociation) {
                await mapExistingWorkspaceAsync(workspaceInfo.value?.[0].name);
            } else {
                onInitialAccountAndWorkspaceProvisioningSuccess?.();
                onWorkspaceMappingCompletion?.();
                return;
            }
        } else {
            // Multiworkspaces, we need to return the user to the previous screen
            // they need to create and select a workspace and data storage location
            if (isWorkspacesTestingEnabled) {
                onWorkspaceProvisionError?.('No workspace found for capacity linking');
                return;
            }
            await provisionAndMapNewWorkspaceAsync();
        }
    };

    /** Responsible for checking workspace for MultiWorkspaces from the cache and linking the capacity */
    const checkAndProvisionGeoWorkspace = async () => {
        let cachedWorkspaceName: string | undefined;

        console.log('Checking for existing workspace in session storage cache to link capacity...');
        const workspaceCache = getCurrentWorkspaceCache();
        cachedWorkspaceName = workspaceCache?.name || undefined;
        console.log('workspaceCache workspace name:', workspaceCache?.name);
        console.log('skipworkspaceAssociation:', skipWorkspaceAssociation);

        if (cachedWorkspaceName) {
            if (!skipWorkspaceAssociation) {
                if (isWorkspacesTestingEnabled) {
                    console.log('Linking capacity to existing workspace...');
                    console.log('workspaceInfo:', cachedWorkspaceName);
                }
                await mapExistingWorkspaceAsync(cachedWorkspaceName);

                console.log('Capacity linked successfully!');
                onWorkspaceMappingCompletion?.();
            } else {
                onInitialAccountAndWorkspaceProvisioningSuccess?.();
                onWorkspaceMappingCompletion?.();
                return;
            }
        } else {
            // Multiworkspaces, we need to return the user to the previous screen
            // they need to create and select a workspace and data storage location
            if (isWorkspacesTestingEnabled) {
                onWorkspaceProvisionError?.('No workspace found for capacity linking');
                return;
            }
            await provisionAndMapNewWorkspaceAsync();
        }
    };

    // This function creates a new capacity
    const createCapacityAsync = async () => {
        try {
            const capacityRegion =
                !!MedeinaVariables.DefaultCapacityRegion &&
                MedeinaVariables.DefaultCapacityRegion !== 'none'
                    ? MedeinaVariables.DefaultCapacityRegion
                    : region || '';
            let createCapacityRequest = {
                name: capacityName || '',
                location: capacityRegion,
                properties: {
                    numberOfUnits: Number(capacityUnits),
                    crossGeoCompute: isCrossRegionAllowed
                        ? CrossRegionCompute.Allowed
                        : CrossRegionCompute.NotAllowed,
                    geo: geo || '',
                },
            };
            if (isOverageEnabled) {
                let overageParams = {
                    overageState: overageState || OverageState.None,
                    overageAmount: overageAmount || undefined,
                };
                createCapacityRequest = {
                    ...createCapacityRequest,
                    properties: {
                        ...createCapacityRequest.properties,
                        ...overageParams,
                    },
                };
            }
            if (isWorkspacesTestingEnabled) {
                console.log('Creating capacity in multi-workspaces scenario...');
                console.log('capacityName:', capacityName, {createCapacityRequest});
            }
            await createCapacity({
                ...createCapacityRequest,
            });
            if (isWorkspacesTestingEnabled) {
                console.log('Capacity created successfully');
            }
            if (isWorkspacesTestingEnabled) {
                await checkAndProvisionGeoWorkspace();
            } else {
                // GA flow
                await checkAndProvisionWorkspace();
            }
        } catch (error) {
            console.log({error});
            onCapacityCreationError?.(error);
            return;
        }
    };

    // This function provisions a new workspace and maps it to the capacity
    const provisionAndMapNewWorkspaceAsync = async () => {
        try {
            await provisionWorkspaceWithRetries();
            // Wait for the workspace to be created at Fidelis
            await new Promise((resolve) =>
                setTimeout(resolve, PROVISION_WORKSPACE_CONFIRM_INTERVAL),
            );

            // Get the workspace after the creation to confirm it was really created
            const workspace = await getWorkspaces();
            const workspaceName = workspace?.data?.value?.[0]?.name;
            if (!workspaceName || workspaceName !== newWorkspaceName) {
                // If we do not have a workspace after the creation and the retries we emit an error
                onWorkspaceProvisionError?.('No workspace found after creation');
                return;
            }

            await mapExistingWorkspaceAsync(newWorkspaceName);
        } catch (error) {
            onWorkspaceProvisionError?.(error);
        }
    };

    const provisionGeoWorkspace = async (requestWorkspaceName?: string, geoName?: string) => {
        const _newWorkspaceName = requestWorkspaceName || newWorkspaceName || 'default';
        try {
            // Provision the workspace using the multi workspace flow
            // use new put workspace
            console.log('Attempting to create workspace in multi-workspaces scenario...');
            console.log(`workspaceName: ${_newWorkspaceName}, geoName: ${geoName}`);
            const freWorkspace = await handleCreateFreWorkspace({
                name: _newWorkspaceName,
                capacity: {
                    type: 'CapacityReference',
                    referenceName: capacityName || '',
                },
                dataStorageLocation: geoName || '',
            });
            return freWorkspace;
        } catch (error) {
            throw new Error('Failed to provision workspace');
        }
    };

    const provisionWorkspaceWithRetries = async () => {
        let isWorkspaceFound = false;
        for (let i = 0; i < PROVISION_ATTEMPT_COUNT && !isWorkspaceFound; i++) {
            try {
                // Attempt to provision the workspace
                if (isWorkspacesTestingEnabled) {
                    // use new put workspace
                    console.log('Attempting to create workspace in multi-workspaces scenario...');
                    await handleCreateWorkspace({
                        name: newWorkspaceName,
                        capacity: {
                            type: 'CapacityReference',
                            referenceName: capacityName || '',
                        },
                        dataStorageLocation: geo || '',
                    });
                    // TODO: set up with correct values
                    const workspaceSettings: WorkspaceSettings = {
                        workspaceId: newWorkspaceName,
                        tenantId: '',
                        freRequirements: {},
                    };
                    await provisionWorkspaceSettings({
                        workspaceSettings,
                    });
                } else {
                    await provisionWorkspace({
                        defaultWorkspaceName: newWorkspaceName,
                    });
                    const workspaces = await getWorkspaces();
                    // in GA flow there's only one workspace
                    const workspace = workspaces?.data?.value?.[0];
                    // store the new workspace information in session storage
                    if (workspace) {
                        formatWorkspaceToSessionData(workspace);
                    } else {
                        throw new Error('Failed to provision workspace');
                    }
                }

                // If the workspace was provisioned successfully, exit the function
                return;
            } catch (error) {
                // If the workspace was not provisioned successfully and we have no retries left, throw the error
                // Wait for 1 second before the next attempt
                await new Promise((resolve) => setTimeout(resolve, PROVISION_ATTEMPT_INTERVAL));
            }
        }
        if (!isWorkspaceFound) {
            throw new Error('Failed to provision workspace');
        }
    };

    const provisionSubscriptionWithProvider = async (): Promise<boolean> => {
        try {
            let subscriptionRegistered = false;
            for (var i = 0; i < SUBSCRIPTION_REGISTRATION_ATTEMPT_COUNT; i++) {
                // Attempt to register the subscription with the provider
                const result = await registerSubscriptionWithProvider({
                    subscriptionId: subscription || '',
                });

                // If the subscription was registered successfully, update the exist state
                subscriptionRegistered = result?.registrationState === 'Registered';

                if (subscriptionRegistered) {
                    // If the subscription was registered successfully, exit the loop
                    break;
                } else {
                    // Wait for 2 seconds before the next attempt, if the subscription was not registered
                    await new Promise((resolve) =>
                        setTimeout(resolve, SUBSCRIPTION_REGISTRATION_ATTEMPT_INTERVAL),
                    );
                }
            }

            if (!subscriptionRegistered) {
                // If the subscription is not registered even after repeated attempts, then we exit the capacity creation process
                throw new Error('Failed to register subscription with provider');
            }

            return true;
        } catch (error) {
            onSubscriptionRegistrationError?.(error);
            return false;
        }
    };

    // This function maps an existing workspace to the capacity
    const mapExistingWorkspaceAsync = async (workspaceName: string) => {
        // Call the useGetWorkspaceByName function to get current workspace data
        const {data: workspaceData} = await refetchWorkspaceByName();
        const optInConfig = workspaceData?.workspaceOptInConfig;
        if (isWorkspacesTestingEnabled) {
            // temporarily log the workspace creation
            console.log(
                `Linking workspace:${workspaceName} and capacity:${capacityName}`,
                workspaceData,
            );
        }
        try {
            await mapWorkspace({
                name: workspaceName || '',
                capacity: {
                    type: 'CapacityReference',
                    referenceName: capacityName || '',
                },
                workspaceOptInConfig: {
                    isAllowModelImprovement: !!optInConfig
                        ? optInConfig.isAllowModelImprovement
                        : 'true',
                    isAllowProductImprovement: !!optInConfig
                        ? optInConfig.isAllowProductImprovement
                        : 'true',
                },
            });
            if (isWorkspacesTestingEnabled) {
                // temporarily log the workspace creation
                console.log(
                    `Capacity linked! workspace:${workspaceName} and capacity:${capacityName}`,
                );
            }
            onWorkspaceMappingCompletion?.(); // Assuming there's a way to pass relevant data or handle completion
        } catch (error) {
            console.warn(
                `Error mapping workspace:${workspaceName} and capacity:${capacityName}`,
                error,
            );
            console.warn();
            onWorkspaceMappingError?.(error);
        }
    };

    // This function is the main entry point for the setup and provisioning of capacity
    const provisionCapacityLinkage = async () => {
        if (isNewResourceGroup) {
            await createResourceGroupIfNew();
        }
        await createAccountAsync();
    };

    /** Setup screen: this is called when the user is submitting their capacity information */
    const provisionCapacityGeoLinkage = async () => {
        console.log('starting capacity provisioning...');
        /** The form allows for the creation of  */
        if (isNewResourceGroup) {
            await createResourceGroupIfNew();
        }
        /** In multiworkspaces we have already provisioned a workspace and account */
        try {
            if (!isCapacityProvisioned) {
                // Try to register the subscription with the provider. The error handling is done internally
                console.log('Registering subscription with provider...');
                const registrationResult = await provisionSubscriptionWithProvider();

                if (!registrationResult) {
                    // If the registration failed we return
                    return;
                }
                console.log('Registration successful, creating capacity...');
                await createCapacityAsync();
            } else {
                // there's already a capacity provisioned
                // mapExistingWorkspaceAsync();
            }
        } catch (error) {
            onAccountCreationError?.(error);
        }
    };

    /** Multiworkspaces provisioning
     * Provision MSG account
     * Enable the first geo
     * Provision the workspace
     */
    const provisionAccountAndGeoWorkspace = async (request: SetupGeoAccountAndWorkspaceProps) => {
        console.log(
            `Provisioning account and workspace... selectedGeo: ${request?.selectedGeo} workspaceName: ${request?.workspaceName}`,
        );
        try {
            const result = await getAccount();
            const accountDetails = result.data;

            if (!accountDetails?.accountId) {
                console.log('No msg account found, provisioning account...');
                const provisionAccountParams: ProvisionAccountRequest = {
                    configuredGeo: request?.selectedGeo,
                };

                await provisionAccount(provisionAccountParams);

                /** Geopod required
                 * Refetch the account to get the updated account details and store the workspace in session storage
                 */
                const updatedAccount = await getAccount();
                if (updatedAccount.isError || !updatedAccount.data) {
                    onAccountCreationError?.(updatedAccount.error);
                    return;
                }
            }
            // // Register the subscription with the provider
            // const registrationResult = await provisionSubscriptionWithProvider();
            // console.log('Subscription registration result:', registrationResult);

            // Check for an existing workspace
            const existingWorkspace = await getWorkspaceBeforeFre();
            // Step out if the workspace already exists
            if ((existingWorkspace?.data?.count ?? 0) > 0) {
                onInitialAccountAndWorkspaceProvisioningSuccess?.();
                return;
            }
            await provisionGeoWorkspace(request?.workspaceName, request?.selectedGeo);
            // Get the workspace after the creation to confirm it was really created
            const workspace = await getWorkspaces();
            /** In GA we do a general check for the first workspace
             * in multiworkspaces we need to check if the workspace is included in the list
             */
            let hasWorkspace;
            hasWorkspace = workspace?.data?.value?.find((w) => w.name === request?.workspaceName);

            if (!hasWorkspace) {
                // If we do not have a workspace after the creation and the retries we emit an error
                onWorkspaceProvisionError?.('No workspace found after creation');
            }
            // Return the control to the caller
            onInitialAccountAndWorkspaceProvisioningSuccess?.();
        } catch (error) {
            // catch possible errors from the calls we make
            // account creation error
            // workspace creation error
            // workspaceSetting creation error
            if (error instanceof FidelisApiError) {
                if (error.errorType !== FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS) {
                    onAccountFetchError?.(error);
                    return;
                }
            } else if (Boolean(error)) {
                onAccountCreationError?.(error);

                onAccountFetchError?.(error);
                return;
            }
        }
    };

    const provisionAccountAndWorkspaceAsync = async (request?: SetupAccountAndWorkspaceProps) => {
        let accountDetails: AccountDetails | undefined;

        if (MedeinaFeatures.EnableGeoSelection) {
            try {
                const result = await getAccount();
                accountDetails = result.data;
            } catch (error) {
                if (error instanceof FidelisApiError) {
                    if (error.errorType !== FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS) {
                        onAccountFetchError?.(error);
                        return;
                    }
                } else if (Boolean(error)) {
                    onAccountFetchError?.(error);
                    return;
                }
            }
        }

        if (
            (!Boolean(accountDetails) && MedeinaFeatures.EnableGeoSelection) ||
            !MedeinaFeatures.EnableGeoSelection
        ) {
            // If we do not have an account we provision one. This is only if Geo Selection feature is available

            try {
                // Try to perform the account provisioning

                const provisionAccountParams = MedeinaFeatures.EnableGeoSelection
                    ? ({
                          configuredGeo: request?.selectedGeo,
                      } as ProvisionAccountRequest)
                    : null;

                await provisionAccount(provisionAccountParams);
            } catch (error) {
                // If there is an error we emit the error
                onAccountCreationError?.(error);
                return;
            }
        }

        try {
            // Get the existing workspace
            const existingWorkspace = await getWorkspaceBeforeFre();

            // Step out if the workspace already exists
            if ((existingWorkspace?.data?.count ?? 0) > 0) {
                onInitialAccountAndWorkspaceProvisioningSuccess?.();
                return;
            }

            await provisionWorkspaceWithRetries();
            // Wait for the workspace to be created at Fidelis
            await new Promise((resolve) =>
                setTimeout(resolve, PROVISION_WORKSPACE_CONFIRM_INTERVAL),
            );
            // Get the workspace after the creation to confirm it was really created
            const workspace = await getWorkspaces();
            const workspaceName = workspace?.data?.value?.[0]?.name;
            if (!workspaceName || workspaceName !== newWorkspaceName) {
                // If we do not have a workspace after the creation and the retries we emit an error
                onWorkspaceProvisionError?.('No workspace found after creation');
            }
            // Return the control to the caller
            onInitialAccountAndWorkspaceProvisioningSuccess?.();
        } catch (error) {
            onWorkspaceProvisionError?.(error);
            return;
        }
    };

    return {
        provisionCapacityLinkage,
        provisionCapacityGeoLinkage,
        provisionAccountAndWorkspaceAsync,
        provisionAccountAndGeoWorkspace,
        mapExistingWorkspaceAsync,
    };
}
