import {useTranslation} from 'react-i18next';
import {Button, Input, mergeClasses} from '@fluentui/react-components';
import {useState} from 'react';

import useClasses from './PluginBuilder.styles';
import {Build} from './components';
import {useParams} from 'react-router-dom';
import {PluginBuilderParams} from './PluginBuilder.types';
import {AcceptIcon, EditIcon} from '@/components/ui/icons';

export default function PluginBuilder() {
    const classes = useClasses();
    const {t} = useTranslation('pluginBuilder');
    const [title, setTitle] = useState<string>(t('BuilderTitle'));
    const [editMode, setEditMode] = useState<boolean>(false);

    const {pluginName} = useParams() as PluginBuilderParams;

    return (
        <div>
            <div className={classes.header}>
                {editMode ? (
                    <>
                        <Input
                            appearance={'underline'}
                            className={classes.title}
                            type={'text'}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <Button
                            aria-label="Accept Title Change"
                            className={classes.editButton}
                            appearance={'transparent'}
                            onClick={() => setEditMode(false)}
                        >
                            <AcceptIcon />
                        </Button>
                    </>
                ) : (
                    <>
                        <a className={classes.title}>{title}</a>
                        <Button
                            aria-label="Edit Title"
                            className={classes.editButton}
                            appearance={'transparent'}
                            onClick={() => setEditMode(true)}
                        >
                            <EditIcon />
                        </Button>
                    </>
                )}
                <div className={classes.controlButtons}>
                    <Button aria-label={t('PublishButton')}>{t('PublishButton')}</Button>
                </div>
            </div>
            <Build pluginName={pluginName ?? ''} />,
        </div>
    );
}
