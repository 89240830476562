import {Badge, Body1, Skeleton, SkeletonItem} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {useGetUserProfileByUserId} from '@/api/user';
import type {AgentIdentity} from '@/api/agents';
import useClasses from './AgentIdentityDisplay.styles';

export interface AgentIdentityDisplayProps {
    identity: AgentIdentity | undefined;
}

export default function AgentIdentityDisplay({identity}: AgentIdentityDisplayProps) {
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();

    const {
        data: user,
        isLoading: isUserLoading,
        isError: isUserError,
    } = useGetUserProfileByUserId(identity?.capturedUserId ?? '');

    return (
        <div className={classes.root} data-testid={'agent-identity'}>
            <Body1>{tAgents('Agent.Details.IdentityDescription')}</Body1>
            {isUserLoading && (
                <Skeleton appearance="translucent" animation="pulse" className={classes.skeleton}>
                    <SkeletonItem className={classes.skeletonItem} />
                </Skeleton>
            )}
            {!isUserLoading && (
                <Badge size="medium" color="informative" appearance="outline">
                    {user?.mail ??
                        user?.displayName ??
                        (identity?.capturedUserId && isUserError
                            ? tAgents('Agent.Details.UnknownIdentity')
                            : tAgents('Agent.Details.PermissionsPlaceholder'))}
                </Badge>
            )}
        </div>
    );
}
