import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import handleError from '@/api/handleError';
import {RunAgentTriggerRequest, AgentExecution, AgentsRequestQueryParameters} from './agents.types';

export default function useRunAgentTrigger(requestParams: AgentsRequestQueryParameters) {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (request: RunAgentTriggerRequest) => {
            const queryParams = new URLSearchParams(requestParams);
            const response = await customFetch<Response>(
                `/agents/${request.agentId}/triggers/${
                    request.triggerName
                }/executions?${queryParams.toString()}`,
                {
                    method: 'POST',
                    body: JSON.stringify(request.body),
                },
                true,
            );

            if (response.ok) {
                const {executionId}: AgentExecution = await response.json();

                // Polling until the execution is finished.
                let execution: AgentExecution | undefined;
                try {
                    do {
                        // Wait between requests.
                        await new Promise((resolve) => setTimeout(resolve, 5000));
                        // Re-fetch runs while the execution is working.
                        queryClient.refetchQueries({
                            queryKey: [
                                'agents',
                                request.agentId,
                                'triggers',
                                request.triggerName,
                                'runs',
                                requestParams,
                            ],
                        });
                        // Refetch the execution.
                        execution = await customFetch<AgentExecution>(
                            `/agents/${request.agentId}/triggers/${
                                request.triggerName
                            }/executions/${executionId}?${queryParams.toString()}`,
                            {
                                method: 'GET',
                            },
                        );
                    } while (execution.status !== 'Completed' && execution.status !== 'Failed');
                } catch (error) {
                    // We don't want to throw an error if polling fails since the trigger run completed.
                    console.error('Error fetching execution status:', error);
                }
                return execution;
            } else {
                handleError(response, 'Failed to run agent trigger');
            }
        },
        onSuccess: (_, request) => {
            queryClient.refetchQueries({
                queryKey: [
                    'agents',
                    request.agentId,
                    'triggers',
                    request.triggerName,
                    'runs',
                    requestParams,
                ],
            });
        },
    });
}
