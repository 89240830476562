import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    drawer: {
        boxShadow: tokens.shadow8,
        width: '700px',
    },
    drawerHeader: {
        ...shorthands.padding(tokens.spacingHorizontalXXL),
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
    },
    drawerActions: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
    },
    drawerHeaderStatus: {
        display: 'flex',
        gap: tokens.spacingHorizontalL,
    },
    drawerBody: {
        ...shorthands.padding(0),
    },
    error: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        ...shorthands.padding(tokens.spacingVerticalXXL, 0),
    },
    inputContainer: {
        ...shorthands.padding(tokens.spacingVerticalXXXL, tokens.spacingHorizontalXXL),
    },
    inputActions: {
        display: 'flex',
        justifyContent: 'end',
    },
    loading: {
        width: '100%',
        height: '100%',
    },
    statusLabel: {
        paddingLeft: '0 !important', // Overriding the default padding from SFE StatusLabel
    },
});
