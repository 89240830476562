import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentDetails.styles';
import {
    Body1,
    Caption1,
    Card,
    CardHeader,
    Body1Strong,
    Body1Stronger,
    Link,
    Divider,
} from '@fluentui/react-components';
import AgentPlugins from '../Plugins/AgentPlugins';
import AgentIdentityDisplay from '../AgentItentityDisplay/AgentIdentityDisplay';
import {Agent, AgentDefinition, AgentIdentity} from '@/api/agents';
import AgentPermissions from '../Permissions/AgentPermissions';
import formatTime from '@/util/timeFormatterUtil';
import useAgentStatus from '../useAgentStatus';

interface AgentDetailsProps {
    agent: Agent;
    identity: AgentIdentity;
    definition: AgentDefinition;
}

export default function AgentDetails(props: AgentDetailsProps) {
    const {agent, identity, definition} = props;
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();

    const {isAutomaticTrigger, isTriggerActive} = useAgentStatus(agent, definition);

    return (
        <div className={classes.container}>
            <Card className={classes.card} data-testid="agent-about-card">
                <CardHeader
                    header={<Body1Strong>{tAgents('Agent.Details.AboutHeader')}</Body1Strong>}
                />
                <Body1>
                    {definition.description
                        ? definition.description
                        : tAgents('Agent.Details.NoDescription')}
                </Body1>
            </Card>
            <Card className={classes.card} data-testid="agent-trigger-card">
                <CardHeader
                    header={<Body1Strong>{tAgents('Agent.Details.TriggerHeader')}</Body1Strong>}
                />
                {!isAutomaticTrigger && !isTriggerActive ? (
                    <Caption1 data-testid={'agent-trigger-manual'}>
                        {tAgents('Agent.Details.TriggerDescriptionManual')}
                    </Caption1>
                ) : (
                    <>
                        <Body1Stronger data-testid={'agent-trigger-text'}>
                            {isTriggerActive
                                ? tAgents('Agent.Details.TriggerOn')
                                : tAgents('Agent.Details.TriggerOff')}
                        </Body1Stronger>
                        <Caption1>
                            {tAgents('Agent.Details.TriggerDescription', {
                                triggerInterval: formatTime(
                                    isTriggerActive
                                        ? agent.triggers?.[0].pollPeriodSeconds ?? 0
                                        : definition.triggers?.[0]?.defaultPollPeriodSeconds ?? 0,
                                ),
                            })}
                        </Caption1>
                    </>
                )}
            </Card>
            <Card className={classes.card} data-testid="agent-plugins-card">
                <CardHeader
                    header={<Body1Strong>{tAgents('Agent.Details.PermissionsHeader')}</Body1Strong>}
                />
                <div className={classes.cardSection} data-testid={'agent-permissions'}>
                    <AgentPermissions requiredSkillsets={definition.requiredSkillsets} />
                </div>
                <Divider />
                <div className={classes.cardSectionTitle}>
                    <Body1Strong>{tAgents('Agent.Details.IdentityHeader')}</Body1Strong>
                </div>
                <AgentIdentityDisplay identity={identity} />
                <Divider />
                <div className={classes.cardSectionTitle}>
                    <Body1Strong>{tAgents('Agent.Details.PluginsHeader')}</Body1Strong>
                </div>
                <div className={classes.cardSection} data-testid={'agent-plugins'}>
                    <AgentPlugins requiredSkillsets={definition.requiredSkillsets} />
                </div>
                <Divider />
                <div className={classes.cardSectionTitle}>
                    <Body1Strong>{tAgents('Agent.Details.RbacHeader')}</Body1Strong>
                </div>
                <div className={classes.cardSection} data-testid={'agent-rbac'}>
                    <Body1>{tAgents('Agent.Details.RbacDescription')}</Body1>
                </div>
            </Card>
        </div>
    );
}
