import {DismissIcon} from '@/components/ui/icons';
import MedeinaVariables from '@/util/variables';
import {
    Button,
    Link,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle,
} from '@fluentui/react-components';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function AgentsPreviewMessage() {
    const {t} = useTranslation('common');
    const {t: tAgents} = useTranslation('agents');
    const [isVisible, setIsVisible] = useState(true);
    const dismissMessage = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <>
            <MessageBar intent="info" layout="singleline" data-testid="agents-preview-message">
                <MessageBarBody>
                    <MessageBarTitle>{tAgents('AgentPreviewMessage.Title')} </MessageBarTitle>
                    {tAgents('AgentPreviewMessage.Body')}{' '}
                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <Button
                            onClick={() => dismissMessage()}
                            appearance="transparent"
                            aria-label="dismiss"
                            icon={<DismissIcon />}
                        />
                    }
                />
            </MessageBar>
        </>
    );
}
