import React from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentActivity.styles';
import {Caption1, Card, CardHeader, Body1Strong, Spinner} from '@fluentui/react-components';
import AgentActivityTable from './AgentActivityTable';
import AgentActivityEmptyState from './AgentActivityEmptyState';
import {
    AgentTriggerRun,
    Agent,
    useGetAgentTriggerRuns,
    AgentScopeTypes,
    AgentDefinition,
} from '@/api/agents';
import {StatusLabel} from '@sfe/react';
interface AgentActivityProps {
    setSelectedRun: (run: AgentTriggerRun | undefined) => void;
    onEnableTrigger: (enabled: boolean) => void;
    onManualRun: () => void;
    agentId: string;
    agent: Agent;
    definition: AgentDefinition;
}

export default function AgentActivity(props: AgentActivityProps) {
    const {setSelectedRun, onEnableTrigger, onManualRun, agentId, agent, definition} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const {
        data: triggerRuns,
        isLoading: isTriggerRunsLoading,
        isError: isTriggerRunsError,
    } = useGetAgentTriggerRuns({
        agentId,
        triggerName: agent.triggers?.[0]?.name,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    return (
        <div className={classes.activity}>
            <Card className={classes.activityCard} data-testid="agent-activity-card">
                <CardHeader
                    header={
                        <div className={classes.activityHeader} data-testid="agent-activity-header">
                            <Body1Strong>{tAgents('Agent.Activity.Header')}</Body1Strong>{' '}
                        </div>
                    }
                    description={
                        <div>
                            <Caption1>{tAgents('Agent.Activity.Description')}</Caption1>
                        </div>
                    }
                />
                {isTriggerRunsLoading && !isTriggerRunsError && (
                    <div className={classes.loading}>
                        <Spinner
                            labelPosition="below"
                            label={tAgents('Agent.Activity.Loading')}
                            data-testid="agent-loading-spinner"
                        />
                    </div>
                )}
                {!isTriggerRunsLoading && isTriggerRunsError && (
                    <div className={classes.loading}>
                        <StatusLabel status="failed" text={tAgents('Agent.Activity.Error')} />
                    </div>
                )}
                {!isTriggerRunsLoading && triggerRuns && (
                    <>
                        {triggerRuns.values.length > 0 ? (
                            <AgentActivityTable
                                setSelectedRun={setSelectedRun}
                                runs={triggerRuns.values}
                            />
                        ) : (
                            <AgentActivityEmptyState
                                agent={agent}
                                definition={definition}
                                onEnableTrigger={onEnableTrigger}
                                onRunTrigger={onManualRun}
                            />
                        )}
                    </>
                )}
            </Card>
        </div>
    );
}
