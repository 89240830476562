import React, {useCallback} from 'react';
import useClasses from './AgentDrawer.styles';
import {useTranslation} from 'react-i18next';
import {
    Button,
    DrawerHeader,
    DrawerBody,
    DrawerHeaderTitle,
    Card,
    CardHeader,
    CardFooter,
    Subtitle2,
} from '@fluentui/react-components';
import {Dismiss24Regular, Send24Regular} from '@fluentui/react-icons';
import type {Agent, AgentDefinition, RunAgentTriggerRequest} from '@/api/agents';
import AgentSettingsConfiguration from '../AgentConfigurationDialog/components/AgentSettingsConfiguration/AgentSettingsConfiguration';
import useAgentSettingsConfigurationForm from '../AgentConfigurationDialog/components/AgentSettingsConfiguration/useAgentSettingsConfigurationForm';

interface AgentInputDrawerContentProps {
    agent: Agent;
    agentDefinition: AgentDefinition;
    onRunTrigger: (body: RunAgentTriggerRequest['body']) => void;
    onClose: () => void;
    isManualRunLoading: boolean;
}

export default function AgentInputDrawerContent(props: AgentInputDrawerContentProps) {
    const {agent, agentDefinition, onRunTrigger, onClose, isManualRunLoading} = props;
    const classes = useClasses();
    const {t} = useTranslation(['common', 'agents']);

    const {
        formValues,
        setFormValues,
        failedValidationSettings,
        validateSettings,
        processFormValues,
    } = useAgentSettingsConfigurationForm(agentDefinition, agent);

    const handleSubmit = useCallback(() => {
        if (!validateSettings()) {
            return;
        }

        const {settings, triggerSettings} = processFormValues();

        onRunTrigger({
            Inputs: {...Object.fromEntries(settings), ...Object.fromEntries(triggerSettings)},
        });
    }, [validateSettings, processFormValues, onRunTrigger]);

    return (
        <>
            <DrawerHeader className={classes.drawerHeader} data-testid={'agent-drawer-header'}>
                <DrawerHeaderTitle
                    action={
                        <div className={classes.drawerActions}>
                            <Button
                                appearance="subtle"
                                aria-label={t('common:ButtonLabels.Close')}
                                icon={<Dismiss24Regular />}
                                data-testid={'agent-drawer-close-button'}
                                onClick={onClose}
                            />
                        </div>
                    }
                >
                    {t('agents:Agent.Drawer.Input.Header')}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody className={classes.drawerBody} data-testid={'agent-drawer-body'}>
                <div className={classes.inputContainer}>
                    <Card>
                        <CardHeader
                            header={
                                <Subtitle2>{t('agents:Agent.Drawer.Input.CardHeader')}</Subtitle2>
                            }
                        />
                        <AgentSettingsConfiguration
                            agentDefinition={agentDefinition}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            failedValidationSettings={failedValidationSettings}
                        />
                        <CardFooter className={classes.inputActions}>
                            <Button
                                icon={<Send24Regular />}
                                appearance="primary"
                                data-testid="agent-input-submit-button"
                                onClick={handleSubmit}
                                disabled={isManualRunLoading}
                            >
                                {t('common:ButtonLabels.Submit')}
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            </DrawerBody>
        </>
    );
}
