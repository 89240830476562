import {useQuery} from '@tanstack/react-query';
import {AgentTriggerRun, AgentTriggerRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentTriggerRun({
    agentId,
    triggerName,
    runId,
    requestParams,
}: AgentTriggerRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['agents', agentId, 'triggers', triggerName, 'run', runId, requestParams],
        refetchOnWindowFocus: false,
        enabled: !!agentId && !!triggerName && !!runId,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<AgentTriggerRun>(
                `/agents/${agentId}/triggers/${triggerName}/runs/${runId}?${queryParams.toString()}`,
                {method: 'GET'},
            );
        },
        retry: false,
        refetchInterval: (data) => {
            if (!data || (data?.status !== 'InProgress' && data?.status !== 'Pending')) {
                return false;
            }

            // If the run is in progress, refetch every 3 seconds
            return 3000;
        },
    });
}
