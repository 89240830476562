import {useState, useEffect, useMemo, useRef} from 'react';
import useClasses from '../ManageWorkspaces.styles';
import {
    TableCellLayout,
    Menu,
    MenuButton,
    MenuTrigger,
    MenuGroup,
    MenuGroupHeader,
    MenuDivider,
    MenuPopover,
    MenuList,
    MenuItem,
    Link,
    useId,
    Toast,
    ToastTitle,
    useToastController,
    Toaster,
    Tooltip,
} from '@fluentui/react-components';
import {AddIcon, OpenIcon, CheckmarkIcon} from '@/components/ui/icons';
import {Capacity} from '@/api/capacities/capacities.types';
import {useGetWorkspaces, Workspace} from '@/api/workspaces';
import {useGetCapacityByName, useGetCapacities} from '@/api/capacities';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import DisconnectCapacityDialog from '../DisconnectCapacityDialog';
import CreateCapacity from '@/components/CreateCapacity';
import usePutWorkspace from '@/api/workspaces/usePutWorkspace';
import WorkspacesLoadingOverlay from '../WorkspacesLoadingOverlay';
import ErrorSettingWorkspace from '@/components/Navigation/Breadcrumbs/ErrorSettingWorkspace';

interface CapacityColumnProps {
    workspace: Workspace;
    isWorkspacesDataFetched: boolean;
    handleSuccess: () => void;
    disconnectedWorkspaces: Workspace[];
    setDisconnectedWorkspaces: (workspace: Workspace[]) => void;
    disconnectedCapacities: Capacity[];
    setDisconnectedCapacities: (capacity: Capacity[]) => void;
    unavailableCapacities: Capacity[];
    ownedWorkspaces: string[];
}

export const CapacityColumn: React.FC<CapacityColumnProps> = ({
    workspace,
    isWorkspacesDataFetched,
    handleSuccess,
    disconnectedWorkspaces,
    setDisconnectedWorkspaces,
    disconnectedCapacities,
    setDisconnectedCapacities,
    unavailableCapacities,
    ownedWorkspaces,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    // API call to update workspace capacity linkage
    const putWorkspace = usePutWorkspace();
    const {data: workspacesData} = useGetWorkspaces();
    // make owned workspace array with actual workspace objects
    const ownedWorkspacesData = workspacesData?.value?.filter((workspace: Workspace) =>
        ownedWorkspaces.includes(workspace.name),
    );
    const notOwnedWorkspacesData = workspacesData?.value?.filter(
        (workspace: Workspace) => !ownedWorkspaces.includes(workspace.name),
    );
    const {data: capacityData} = useGetCapacityByName(workspace?.capacity?.referenceName || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });
    const [overlay, setOverlay] = useState<boolean>(false);
    const {data: data} = useGetCapacities({});
    const capacities: Capacity[] = useMemo(
        () =>
            (data?.value || []).filter(
                (capacity) =>
                    !(notOwnedWorkspacesData || []).find(
                        (workspace) => workspace.capacity?.referenceName === capacity.name,
                    ),
            ) || [],
        [data, notOwnedWorkspacesData],
    );

    let capacityDisplayName: string | null = capacityData?.name || null;
    if (
        disconnectedWorkspaces.some(
            (disconnectedWorkspace) => disconnectedWorkspace.name === workspace.name,
        )
    ) {
        capacityDisplayName = null;
    }
    // this should work when backend integration happens
    const [selectedCapacity, setSelectedCapacity] = useState<string | null>(capacityDisplayName);
    const [isSetErrorSwitchingWorkspaceDialogOpen, setErrorSwitchingWorkspaceDialogOpen] =
        useState<boolean>(false);
    // success toast
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);

    // fail toast
    const notifyFail = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('ManageWorkspaces.CapacitySetFailure')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'error'},
        );
    };
    useEffect(() => {
        setSelectedCapacity(capacityDisplayName);
    }, [capacityDisplayName]);
    // check if in disconnected worksapces array and set accordingly

    const [tempSelectedCapacity, setTempSelectedCapacity] = useState<string | null>(null);
    const [attachedWorkspace, setAttachedWorkspace] = useState<Workspace>();

    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);

    const handleMenuItemClick = (capacity: Capacity) => {
        if (capacity.name === selectedCapacity) {
            return;
        }
        const tempCapacityDisplayName = capacity.name;
        setTempSelectedCapacity(capacity.name);
        // check if capacity is part of disconnected capacities
        const isDisconnected = disconnectedCapacities.some(
            (disconnectedCapacity) => disconnectedCapacity.name === capacity.name,
        );
        let tempDisconnectedCapacities: Capacity[] = [...disconnectedCapacities];
        // the capacity chosen is already attached to another workspace
        if (!isDisconnected) {
            // find the corresponding workspace to the selected capacity that is to be disconnected
            // make owned workspace array with actual workspace objects
            const tempAttachedWorkspace = ownedWorkspacesData?.find(
                (workspace: Workspace) => workspace.capacity?.referenceName === capacity.name,
            );
            if (tempAttachedWorkspace) {
                if (ownedWorkspaces.includes(tempAttachedWorkspace.name)) {
                    // set attached workspace for disconnect capacity dialog
                    setAttachedWorkspace(tempAttachedWorkspace);
                    setDisconnectCapacityDialogOpen(true);
                } else {
                }
            } else {
                setErrorSwitchingWorkspaceDialogOpen(true);
            }
        }
        // capacity was available and not attached to any workspace
        else {
            // check if there was a valid original selected capacity
            if (selectedCapacity) {
                // add old capacity to disconnected capacities
                const originalCapacity = capacities.find(
                    (capacity) => capacity.name === selectedCapacity,
                );
                if (originalCapacity) {
                    tempDisconnectedCapacities.push(originalCapacity);
                }
            }
            // set capacity to new capacity
            setOverlay(true);
            // update workspace with new capacity
            putWorkspace.mutate(
                {
                    request: {
                        ...workspace,
                        name: workspace.name,
                        capacity: {
                            type: 'CapacityReference',
                            referenceName: capacity.name,
                        },
                    },
                    targetWorkspace: workspace,
                },
                {
                    onSuccess: () => {
                        capacityDisplayName = tempCapacityDisplayName;
                        setSelectedCapacity(tempCapacityDisplayName);
                        setOverlay(false);
                        handleSuccess();
                        // remove capacity from disconnected capacities
                        tempDisconnectedCapacities = tempDisconnectedCapacities.filter(
                            (c) => c.name !== capacity.name,
                        );
                        setDisconnectedCapacities(tempDisconnectedCapacities);
                        // remove workspace from disconnected workspaces
                        const newDisconnectedWorkspaces = disconnectedWorkspaces.filter(
                            (disconnectedWorkspace) =>
                                disconnectedWorkspace.name !== workspace.name,
                        );
                        setDisconnectedWorkspaces(newDisconnectedWorkspaces);
                    },
                    onError: () => {
                        setOverlay(false);
                        notifyFail();
                    },
                },
            );
        }
    };

    const handleDisconnectConfirm = () => {
        setOverlay(true);
        // disconnect capacity from attached workspace
        putWorkspace.mutate(
            {
                request: {
                    ...attachedWorkspace,
                    name: attachedWorkspace?.name || '',
                    capacity: null,
                },
                targetWorkspace: attachedWorkspace,
            },
            {
                onSuccess: (data) => {
                    putWorkspace.mutate(
                        {
                            request: {
                                ...workspace,
                                name: workspace.name,
                                capacity: {
                                    type: 'CapacityReference',
                                    referenceName: tempSelectedCapacity || '',
                                },
                            },
                            targetWorkspace: workspace,
                        },
                        {
                            onSuccess: () => {
                                if (tempSelectedCapacity) {
                                    // add original capacity to disconnected capacities
                                    const originalCapacity = capacities.find(
                                        (capacity) => capacity.name === selectedCapacity,
                                    );
                                    if (originalCapacity) {
                                        setDisconnectedCapacities([
                                            ...disconnectedCapacities,
                                            originalCapacity,
                                        ]);
                                    }
                                }
                                if (attachedWorkspace) {
                                    // add attached workspace to disconnected workspaces and remove current workspace from disconnected workspaces
                                    const newDisconnectedWorkspaces = disconnectedWorkspaces.filter(
                                        (disconnectedWorkspace) =>
                                            disconnectedWorkspace.name !== workspace.name,
                                    );
                                    setDisconnectedWorkspaces([
                                        ...newDisconnectedWorkspaces,
                                        attachedWorkspace,
                                    ]);
                                }
                                setSelectedCapacity((prev) => tempSelectedCapacity);
                                setOverlay(false);
                                handleSuccess();
                            },
                            onError: () => {
                                setOverlay(false);
                                notifyFail();
                            },
                        },
                    );
                    setDisconnectCapacityDialogOpen(false);
                },
                onError: () => {
                    setOverlay(false);
                    notifyFail();
                },
            },
        );
        setDisconnectCapacityDialogOpen(false);
    };

    // new capacity button ref
    const newCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);

    const [isWorkspaceDisconnected, setIsWorkspaceDisconnected] = useState<boolean>(false);
    useEffect(() => {
        const checkIfWorkspaceDisconnected = disconnectedWorkspaces.some(
            (disconnectedWorkspace) => disconnectedWorkspace.name === workspace.name,
        );
        setIsWorkspaceDisconnected(checkIfWorkspaceDisconnected);
    }, [disconnectedWorkspaces, workspace.name]);
    return (
        <>
            <TableCellLayout className={classes.overflowNameContent}>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        {isWorkspaceDisconnected || !selectedCapacity ? (
                            <MenuButton
                                data-testid="capacity-menu-button"
                                className={classes.errorMenuButton}
                            >
                                {t('ManageWorkspaces.MenuButtons.NoCapacitySelected')}
                            </MenuButton>
                        ) : (
                            <MenuButton appearance="transparent" data-testid="capacity-menu-button">
                                {selectedCapacity}
                            </MenuButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover className={classes.menuPopover}>
                        <MenuList>
                            <MenuGroup>
                                <MenuGroupHeader>
                                    {t('ManageWorkspaces.MenuButtons.CapacitiesYouOwn')}
                                </MenuGroupHeader>
                                {capacities.map((capacity: Capacity, index: number) => {
                                    const isDisconnected = disconnectedCapacities.some(
                                        (disconnectedCapacity) =>
                                            disconnectedCapacity.name === capacity.name,
                                    );
                                    const isUnavailable = unavailableCapacities.some(
                                        (unavailableCapacity) =>
                                            unavailableCapacity.name === capacity.name,
                                    );
                                    return isUnavailable ? (
                                        <Tooltip
                                            content={t(
                                                'ManageWorkspaces.MenuButtons.LinkedCapacity',
                                            )}
                                            relationship="label"
                                            key={index}
                                            onVisibleChange={(_, data) => {
                                                // Override visibility of tooltip to prevent blank tooltip.
                                                if (!isUnavailable) {
                                                    data.visible = false;
                                                }
                                            }}
                                        >
                                            <MenuItem
                                                data-testid="capacity-menu-item"
                                                key={index}
                                                disabled={isUnavailable}
                                                className={
                                                    isDisconnected ? classes.errorMenuButton : ''
                                                }
                                                icon={
                                                    capacity.name === selectedCapacity ? (
                                                        <CheckmarkIcon />
                                                    ) : (
                                                        <div className={classes.fillerIcon} />
                                                    )
                                                }
                                                onClick={() => handleMenuItemClick(capacity)}
                                            >
                                                {isDisconnected
                                                    ? capacity.name +
                                                      ' ' +
                                                      t(
                                                          'ManageWorkspaces.MenuButtons.NotConnectedError',
                                                      )
                                                    : capacity.name}
                                            </MenuItem>
                                        </Tooltip>
                                    ) : (
                                        <MenuItem
                                            data-testid="capacity-menu-item"
                                            key={index}
                                            disabled={isUnavailable}
                                            className={
                                                isDisconnected ? classes.errorMenuButton : ''
                                            }
                                            icon={
                                                capacity.name === selectedCapacity ? (
                                                    <CheckmarkIcon />
                                                ) : (
                                                    <div className={classes.fillerIcon} />
                                                )
                                            }
                                            onClick={() => handleMenuItemClick(capacity)}
                                        >
                                            {isDisconnected
                                                ? capacity.name +
                                                  ' ' +
                                                  t(
                                                      'ManageWorkspaces.MenuButtons.NotConnectedError',
                                                  )
                                                : capacity.name}
                                        </MenuItem>
                                    );
                                })}
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<AddIcon />}
                                    data-testid="new-capacity-menu-item"
                                    onClick={() => {
                                        setIsCreateCapacityDialogOpen(true);
                                    }}
                                >
                                    {t('ManageWorkspaces.MenuButtons.NewCapacity')}
                                </MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<OpenIcon />}
                                    data-testid="manage-capacities-menu-item"
                                >
                                    <Link
                                        href={MedeinaVariables.ManageCapacityAzureUri}
                                        target="_blank"
                                        aria-label={t(
                                            'ManageWorkspaces.ariaLabel.ManageCapacityAzureUri',
                                        )}
                                        appearance="subtle"
                                    >
                                        {t('ManageWorkspaces.MenuButtons.ManageCapacitiesAzure')}
                                    </Link>
                                </MenuItem>
                            </MenuGroup>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <DisconnectCapacityDialog
                    title={t('ManageWorkspaces.DisconnectCapacityDialog.Title', {
                        0: tempSelectedCapacity,
                    })}
                    description={t('ManageWorkspaces.DisconnectCapacityDialog.Description', {
                        0: attachedWorkspace?.name,
                    })}
                    switchButtonLabel={t(
                        'ManageWorkspaces.DisconnectCapacityDialog.DisconnectButton',
                    )}
                    isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                    newCapacityName={tempSelectedCapacity || ''}
                    workspaceName={attachedWorkspace?.name || ''}
                    onClose={() => {
                        setDisconnectCapacityDialogOpen(false);
                    }}
                    onConfirm={handleDisconnectConfirm}
                />
                <CreateCapacity
                    isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                    onCreateCapacityDialogClose={() => {
                        setIsCreateCapacityDialogOpen(false);
                        newCapacityButtonRef.current?.focus();
                    }}
                    hideWorkspaceSelectField={false}
                    workspace={workspace}
                    handleSuccess={handleSuccess}
                />
                <ErrorSettingWorkspace
                    isOpen={isSetErrorSwitchingWorkspaceDialogOpen}
                    onClose={() => {
                        setErrorSwitchingWorkspaceDialogOpen(false);
                    }}
                    title={t('ManageWorkspaces.Errors.SwitchingCapacity')}
                ></ErrorSettingWorkspace>
            </TableCellLayout>
            {overlay && <WorkspacesLoadingOverlay label={t('ManageWorkspaces.SettingCapacity')} />}
            <Toaster toasterId={toasterId} />
        </>
    );
};
