import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    Menu,
    MenuItem,
    MenuItemLink,
    MenuList,
    MenuPopover,
    MenuTrigger,
    SpinButton,
    Spinner,
    Tooltip,
} from '@fluentui/react-components';
import useClasses from './ChangeCapacity.styles';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ExternalLinkIcon, DismissIcon, MoreIcon} from '@/components/ui/icons';
import {OverageState, useGetCapacityByName, usePatchCapacity} from '@/api/capacities';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from './ChangeCapacity.constants';
import DeleteCapacityConfirmation from './DeleteCapacityConfirmation/DeleteCapacityConfirmation';
import UpdateCapacityConfirmation from './UpdateCapacityConfirmation/UpdateCapacityConfirmation';
import MedeinaVariables from '@/util/variables';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import ProvisionCapacityPanel from './ProvisionCapacityPanel';
import usePutCapacity from '@/api/capacities/usePutCapacity';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface ChangeCapacityProps {
    isChangeCapacityDialogOpen: boolean;
    onChangeCapacityDialogClose: () => void;
    isOverageEnabled?: boolean;
    selectedCapacity?: string;
}

export default function ChangeCapacity(props: ChangeCapacityProps) {
    const classes = useClasses();
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {capacityId, capacityName} = useWorkspaceState();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const {mutate: trackEvent} = useTrackEvent();
    const isOverageEnabled = props.isOverageEnabled;
    const [overageState, setOverageState] = useState<OverageState>(OverageState.None);
    const [overageAmount, setOverageAmount] = useState<number>(0);
    const {isChangeCapacityDialogOpen, onChangeCapacityDialogClose} = props;
    const [capacityValue, setCapacityValue] = useState<number>(0);
    const {data: capacity} = useGetCapacityByName(
        isWorkspacesTestingEnabled && props?.selectedCapacity
            ? props?.selectedCapacity
            : capacityName || '',
        {enabled: !!capacityName || !!props?.selectedCapacity},
    );

    const capacityIdFromCapacity = capacity?.id || capacityId || '';
    const capacityNameFromCapacity = capacity?.name || capacityName || '';
    const overageFromCapacity = capacity?.properties?.overageAmount || 0;
    const overageEnabledFromCapacity =
        capacity?.properties?.overageState !== OverageState.None &&
        capacity?.properties?.overageState !== undefined
            ? true
            : false;
    let unlimitedOverageFromCapacity = false;
    const [isOverageNull, setOverageNull] = useState<boolean>(
        capacity?.properties?.overageState === undefined,
    );
    if (capacity?.properties?.overageState === OverageState.Unlimited) {
        unlimitedOverageFromCapacity = true;
    }
    const [overageUnitDetails, setOverageUnitDetails] = useState({
        useOverage: overageEnabledFromCapacity,
        unlimitedOverage: unlimitedOverageFromCapacity,
        limitValue: overageFromCapacity,
    });

    const [capacityToOverageDetails, setCapacityToOverageDetails] = useState({
        useOverage: false,
        unlimitedOverage: false,
        limitValue: 0,
    });
    // Commented out code is for PATCH request to update capacity
    /*
    const {
        mutate: updateCapacityUnits,
        isLoading: isUpdateCapacityLoading,
        isError: isUpdateCapacityError,
    } = usePatchCapacity();*/
    const {
        mutate: updateCapacityUnits,
        isLoading: isUpdateCapacityLoading,
        isError: isUpdateCapacityError,
    } = usePutCapacity();
    const [isDeleteCapacityOpen, setDeleteCapacityOpen] = useState<boolean>(false);
    const [isUpdateCapacitySuccessOpen, setIsUpdateCapacitySuccessOpen] = useState<boolean>(false);
    useEffect(() => {
        if (capacity?.properties.overageState !== undefined) {
            setOverageState(capacity.properties.overageState);
            if (
                overageState !== OverageState.None &&
                capacity.properties.overageAmount !== undefined
            ) {
                setOverageAmount(capacity.properties.overageAmount);
                if (overageState === OverageState.Unlimited) {
                    setOverageUnitDetails({
                        useOverage: true,
                        unlimitedOverage: true,
                        limitValue: 0,
                    });
                } else if (overageState === OverageState.Limited) {
                    setOverageUnitDetails({
                        useOverage: true,
                        unlimitedOverage: false,
                        limitValue: capacity.properties.overageAmount,
                    });
                }
            }
        }
    }, [capacity]);

    useEffect(() => {
        if (capacity?.properties.overageState === undefined) {
            setOverageNull(true);
        } else {
            setOverageNull(false);
        }
    }, [capacity]);

    //This is for display purposes till the actual implementation is performed
    // const [errorMessage, setErrorMessage] = useState<string>(
    //     STATIC_CONTENT.TENANT_READ_ONLY_ACCESS_MESSAGE,
    // );

    // To fetch subscription and resourceGroup while making an ARM API call
    const tenantId = getCurrentTenantId();
    const {getSubscriptionId, getResourceGroupName, getBillingTabUrlOnSubscriptionPage} =
        useAzureCapacityDependencyLinkParts({capacityId: capacityIdFromCapacity || '', tenantId});
    const {subscriptionId, resourceGroupName, manageBillingPageUrl} = useMemo(() => {
        return {
            subscriptionId: getSubscriptionId(),
            resourceGroupName: getResourceGroupName(),
            manageBillingPageUrl: getBillingTabUrlOnSubscriptionPage(),
        };
    }, [capacityIdFromCapacity]);
    const location = capacity?.location;
    // To disable apply updated units value button
    const isCapacityValueUpdated = useMemo<boolean>(() => {
        if (capacity) {
            return capacityValue !== capacity?.properties?.numberOfUnits;
        }
        return false;
    }, [capacityValue, capacity]);

    const haveOverageDetailsChanged = useMemo<boolean>(() => {
        if (!props.isOverageEnabled) {
            return false;
        }

        if (capacity) {
            // compare between overagedetails & capacityToOverageDetails
            // if any of the values changed then return true
            if (overageUnitDetails.useOverage !== capacityToOverageDetails.useOverage) {
                return true;
            }
            if (overageUnitDetails.unlimitedOverage !== capacityToOverageDetails.unlimitedOverage) {
                return true;
            }
            if (overageUnitDetails.limitValue !== capacityToOverageDetails.limitValue) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    }, [capacity, capacityToOverageDetails, overageUnitDetails, props.isOverageEnabled]);

    useEffect(() => {
        if (overageUnitDetails.useOverage) {
            if (overageUnitDetails.unlimitedOverage) {
                setOverageState(OverageState.Unlimited);
            } else if (overageUnitDetails.limitValue > 0) {
                setOverageState(OverageState.Limited);
                setOverageAmount(overageUnitDetails.limitValue);
            }
        } else if (!overageUnitDetails.useOverage) {
            setOverageState(OverageState.None);
        }
    }, [overageUnitDetails]);

    // Actions to take on close/dismiss of change capacity dialog
    const closeCapacityDialog = () => {
        onChangeCapacityDialogClose();
        setDeleteCapacityOpen(false);
        setIsUpdateCapacitySuccessOpen(false);
    };

    // Commented out code below will be reverted when PATCH request to update capacity is enabled for OverageUnits
    const openUpdateCapacity = () => {
        if (capacityNameFromCapacity && capacityIdFromCapacity && capacity?.type && location) {
            /*let updateCapacityRequest = {
                capacityId,
                subscriptionId,
                resourceGroupName,
                capacityName,
                location,
                properties: {
                    numberOfUnits: capacityValue,
                    geo: capacity?.properties.geo,
                    crossGeoCompute: capacity?.properties.crossGeoCompute,
                },
            };*/

            let putCapacityRequest = {
                id: capacityIdFromCapacity,
                capacityName: capacityNameFromCapacity,
                type: capacity?.type,
                subscriptionId,
                resourceGroupName,
                location,
                properties: {
                    numberOfUnits: capacityValue,
                    geo: capacity?.properties.geo,
                    crossGeoCompute: capacity?.properties.crossGeoCompute,
                },
            };

            if (isOverageEnabled) {
                let overageParams;
                // Checking here to see if the previous overageState is null, as we are not forcing customers to use new overage state unless opted in
                // If the customer chooses to use the new overage state, we will set it to one of the three states
                if (isOverageNull && overageState === OverageState.None) {
                    putCapacityRequest = {
                        ...putCapacityRequest,
                        properties: {
                            ...putCapacityRequest.properties,
                        },
                    };
                } else {
                    if (overageState === OverageState.Limited) {
                        overageParams = {
                            overageState: overageState,
                            overageAmount: overageAmount,
                        };
                    } else {
                        overageParams = {
                            overageState: overageState,
                        };
                    }
                    /*updateCapacityRequest = {
                        ...updateCapacityRequest,
                        properties: {
                            ...updateCapacityRequest.properties,
                            ...overageParams,
                        },*/
                    putCapacityRequest = {
                        ...putCapacityRequest,
                        properties: {
                            ...putCapacityRequest.properties,
                            ...overageParams,
                        },
                    };
                }
            }
            updateCapacityUnits(putCapacityRequest, {
                onSuccess: () => {
                    setIsUpdateCapacitySuccessOpen(true);
                },
                onSettled: () => {
                    onChangeCapacityDialogClose();
                },
            });
            /*updateCapacityUnits(updateCapacityRequest, {
                onSuccess: () => {
                    setIsUpdateCapacitySuccessOpen(true);
                },
                onSettled: () => {
                    onChangeCapacityDialogClose();
                },
            });*/
        }
    };
    //To set capacityValue once the capacity has been fetched from Fidelis API
    useEffect(() => {
        setCapacityValue(capacity?.properties?.numberOfUnits || 0);
    }, [capacity]);

    // To reset change capacity dialog on opening with capacity value from API
    useEffect(() => {
        if (isChangeCapacityDialogOpen) {
            setCapacityValue(capacity?.properties?.numberOfUnits || 0);
        }
    }, [isChangeCapacityDialogOpen]);

    // onOpenChange is triggered only on dialog close as the opening is controlled by parent prop, not within <Dialog />.
    return (
        <div data-testid="change-capacity-dialog">
            <Dialog
                open={isChangeCapacityDialogOpen && !isDeleteCapacityOpen}
                onOpenChange={closeCapacityDialog}
            >
                <DialogSurface>
                    <DialogBody data-testid="change-capacity-dialog-body">
                        <DialogTitle className={classes.dialogTitleSection}>
                            {tAdmin(STATIC_CONTENT.SECURITY_COMPUTE_UNITS)}
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    data-testid="close-change-capacity-dialog-button"
                                    appearance="transparent"
                                    aria-label={tAdmin(ARIA_LABELS.CLOSE_CHANGE_CAPACITY_DIALOG)}
                                    icon={<DismissIcon />}
                                />
                            </DialogTrigger>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContentSection}>
                            <div
                                className={classes.contentSection}
                                data-testid="dialog-content-section"
                            >
                                {tAdmin(STATIC_CONTENT.CHANGE_CAPACITY_INFORMATION, {
                                    0: capacityNameFromCapacity,
                                })}{' '}
                                <Link
                                    data-test-id="read-more-about-security-compute-units-link"
                                    target="_blank"
                                    href={MedeinaVariables.SecurityComputeUnitsUri}
                                >
                                    {tAdmin(STATIC_CONTENT.READ_MORE_COMPUTE_UNITS)}
                                </Link>
                            </div>
                            <div>
                                <ProvisionCapacityPanel
                                    isOverageEnabled={isOverageEnabled}
                                    overageUnitDetails={overageUnitDetails}
                                    capacityToOverageDetails={capacityToOverageDetails}
                                    capacityValue={capacityValue}
                                    capacity={capacity}
                                    geo={capacity?.properties.geo}
                                    onCapacityValueChanged={setCapacityValue}
                                    setOverageUnitDetails={setOverageUnitDetails}
                                    setCapacityToOverageDetails={setCapacityToOverageDetails}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                data-test-id="update-capacity-units-button"
                                appearance="primary"
                                onClick={() => {
                                    openUpdateCapacity();
                                    trackEvent({
                                        name: MedeinaTelemetryEvent.Capacity.UpdateCapacity,
                                        eventType: MedeinaEvent.ActionEvent,
                                    });
                                }}
                                disabled={
                                    !(isCapacityValueUpdated || haveOverageDetailsChanged) ||
                                    isUpdateCapacityLoading
                                }
                            >
                                {!isUpdateCapacityLoading ? (
                                    tCommon(CONTROL_LABELS.APPLY)
                                ) : (
                                    <Spinner size="tiny" />
                                )}
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button data-test-id="close-capacity-button" appearance="secondary">
                                    {tCommon(CONTROL_LABELS.CANCEL)}
                                </Button>
                            </DialogTrigger>
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Tooltip
                                        content={tCommon('ButtonLabels.MoreOptions')}
                                        relationship="label"
                                    >
                                        <Button
                                            data-test-id="more-options-button"
                                            icon={<MoreIcon />}
                                        />
                                    </Tooltip>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItemLink
                                            data-test-id="manage-billing-in-azure-link"
                                            href={manageBillingPageUrl}
                                            target="_blank"
                                            role="menuitem"
                                            secondaryContent={
                                                <ExternalLinkIcon aria-label="External link" />
                                            }
                                        >
                                            {tAdmin(STATIC_CONTENT.MANAGE_BILLING_AZURE)}
                                        </MenuItemLink>
                                        <MenuItem
                                            data-test-id="detete-capacity-menu-item"
                                            onClick={() => {
                                                setDeleteCapacityOpen(true);
                                                trackEvent({
                                                    name: MedeinaTelemetryEvent.Capacity
                                                        .DeleteCapacity,
                                                    eventType: MedeinaEvent.ActionEvent,
                                                });
                                            }}
                                            className={classes.deleteCapacityMenuItem}
                                            disabled={!capacity}
                                        >
                                            {tAdmin(CONTROL_LABELS.DELETE_CAPACITY)}
                                        </MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <DeleteCapacityConfirmation
                isDeleteCapacityConfirmDialogOpen={isDeleteCapacityOpen}
                onDeleteCapacityConfirmDialogClose={closeCapacityDialog}
            />
            <UpdateCapacityConfirmation
                isUpdateCapacityConfirmDialogOpen={isUpdateCapacitySuccessOpen}
                onUpdateCapacityConfirmDialogClose={closeCapacityDialog}
                capacityValueToUpdate={capacityValue}
                isOverageEnabled={isOverageEnabled}
                overageCapacity={overageUnitDetails.limitValue}
                overageState={overageState}
                isOverageNull={isOverageNull}
            />
            {isUpdateCapacityError && (
                <ToastNotification
                    intent={Intent.Error}
                    message={tAdmin(STATIC_CONTENT.UPDATE_CAPACITY_ERROR)}
                    sessionId={''}
                />
            )}
        </div>
    );
}
